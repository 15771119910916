import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
	ClientHeader,
	HistoryChart,
	HistoryFilter,
	HistoryOverview,
	HistoryProgress,
	HistoryTransactions,
	NoActivePortfolio,
	PortfolioHeader,
} from '../../components/common';
import { Loader } from '../../components/ui';
import { useLanguage } from '../../contexts/LocaleContext';
import { fetchProfilePortfolio, fetchProfilePortfolioHistory, fetchUserCurrency } from '../../utils/api';
import { fromISODate } from '../../utils/date';
import { useSessionPortfolio } from '../../utils/sessionPortfolio';
import useFetchAuth from '../../utils/useFetchAuth';
import { useFetchWithHardReload } from '../../utils/useFetchWithReload';

const useFetchProfilePortfolio = useFetchWithHardReload(fetchProfilePortfolio);
const useFetchProfilePortfolioHistory = useFetchAuth(fetchProfilePortfolioHistory);
const useFetchUserCurrency = useFetchAuth(fetchUserCurrency);

export default function History({
	accountUrl,
	childOnboardingUrl,
	conditionsUrl,
	faqUrl,
	parentOnboardingUrl,
	proxyId,
	proxyPortfolioId,
	signNewAgreementUrl,
}) {
	const isProxy = proxyId !== null;
	const language = useLanguage();
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [aggregationPeriod, setAggregationPeriod] = useState('day');
	const [evaluation, setEvaluation] = useState(false);
	const [archivePortfolioId, setArchivePortfolioId] = useState(null);
	const [portfolios, loading, error, , reloadPortfolios] = useFetchProfilePortfolio(null, language, isProxy);
	const [portfolioId, portfolio, archiveActive] = useSessionPortfolio(
		!loading && !error ? portfolios : null,
		proxyPortfolioId,
		archivePortfolioId,
	);
	const [history] = useFetchProfilePortfolioHistory(
		null,
		portfolioId,
		language,
		fromDate,
		toDate,
		aggregationPeriod,
		proxyId,
	);
	const [currencies] = useFetchUserCurrency(null, language, isProxy);

	if (loading || error) {
		return <Loader fullPage />;
	}

	return (
		<>
			{!isProxy && (
				<PortfolioHeader
					archiveActive={archiveActive}
					portfolio={portfolio}
					portfolios={portfolios}
					setArchivePortfolioId={setArchivePortfolioId}
					history
				/>
			)}
			{!isProxy && !archiveActive && portfolios?.active?.length > 0 && (
				<ClientHeader
					faqUrl={faqUrl}
					portfolioId={portfolioId}
					portfolios={portfolios?.active ?? null}
				/>
			)}
			{!isProxy && !archiveActive && portfolios?.active?.length === 0 && (
				<NoActivePortfolio
					accountUrl={accountUrl}
					childOnboardingUrl={childOnboardingUrl}
					conditionsUrl={conditionsUrl}
					currencies={currencies}
					parentOnboardingUrl={parentOnboardingUrl}
					portfolios={portfolios}
					reloadPortfolios={reloadPortfolios}
					signNewAgreementUrl={signNewAgreementUrl}
				/>
			)}
			{history !== null ? (
				<>
					<HistoryFilter
						aggregationPeriod={aggregationPeriod}
						fromDate={fromISODate(history?.fromDate ?? null) ?? fromDate}
						minDate={fromISODate(history?.minDate ?? null)}
						toDate={fromISODate(history?.toDate ?? null) ?? toDate}
						loading={loading}
						setAggregationPeriod={setAggregationPeriod}
						setFromDate={setFromDate}
						setToDate={setToDate}
					/>
					<HistoryChart
						currency={history?.currency ?? null}
						data={history?.valueGraph ?? null}
						evaluation={evaluation}
						setEvaluation={setEvaluation}
					/>
					<HistoryOverview
						currency={history?.currency ?? null}
						overview={history?.overview ?? null}
					/>
					<HistoryTransactions
						fromDate={fromDate}
						toDate={toDate}
						currency={history?.currency ?? null}
						portfolioId={portfolioId}
						transactions={history?.transactionHistory ?? null}
						isProxy={isProxy}
					/>
					<HistoryProgress
						fromDate={fromDate}
						toDate={toDate}
						currency={history?.currency ?? null}
						portfolioId={portfolioId}
						progress={history?.valueHistory ?? null}
						isProxy={isProxy}
					/>
				</>
			) : (
				<Loader variant="large" />
			)}
		</>
	);
}

History.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	childOnboardingUrl: PropTypes.string.isRequired,
	conditionsUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	parentOnboardingUrl: PropTypes.string.isRequired,
	proxyId: PropTypes.number,
	proxyPortfolioId: PropTypes.number,
	signNewAgreementUrl: PropTypes.string.isRequired,
};

History.defaultProps = {
	proxyId: null,
	proxyPortfolioId: null,
};
