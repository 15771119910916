export default {
	account: 'my-account',
	blog: 'blog',
	childProduct: 'products/childrens-account',
	classicProduct: 'products/classic-account',
	clientCheck: 'client-check',
	contact: 'ask-us',
	cookies: 'cookies',
	dashboard: 'dashboard',
	documents: 'documents',
	emailAlias: 'linking-accounts',
	emailVerification: 'registration/email-verification',
	esgProduct: 'products/esg-account',
	faq: 'faq',
	fees: 'fees',
	forgotPassword: 'login/password-reset',
	history: 'history',
	homepage: '',
	howItWorks: 'how-it-works',
	login: 'login',
	offers: 'something-extra',
	onboarding: 'onboarding',
	onboardingChild: 'registration-child',
	onboardingParent: 'registration-parent',
	ourApproach: 'our-approach',
	pensionProduct: 'products/pension-investment-account',
	portfolio: 'portfolio',
	privacyPolicy: 'privacy-policy',
	referrals: 'referrals',
	registration: 'registration',
	resetPassword: 'login/password-change',
	signAgreement: 'agreement-signing',
	signNewAgreement: 'new-agreement-signing',
	taxes: 'taxes2023',
	unsubscribe: 'unsubscribe',
};

export const subpages = {
};
