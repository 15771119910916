import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/ui';
import { cancelAccountClosure } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './ChildCancelClosure.module.scss';

export default function ChildCancelClosure({
	child,
	reloadChildrenList,
	reloadUser,
	userClosure,
}) {
	const [t] = useTranslation();
	const cancelAccountClosureAuth = useRequestAuth(cancelAccountClosure);

	const handleCancelClosure = async () => {
		await cancelAccountClosureAuth(child.id);
		reloadChildrenList();
		reloadUser();
	};

	return (
		<div className={styles.root}>
			<div className={styles.content}>
				<div className={styles.icon}>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" width={22} height={20} viewBox="0 0 22 20">
						<path fill="#D84E4B" d="M0 19.5h22L11 .5l-11 19Zm12-3h-2v-2h2v2Zm0-4h-2v-4h2v4Z" />
					</svg>
				</div>
				<div className={styles.wrap}>
					<h2 className={styles.title}>
						{child.firstName}
						{' '}
						{child.lastName}
					</h2>
					<span className={styles.warning}>
						{t('account.childClosure.cancel.warning')}
					</span>
				</div>
				<div className={styles.buttonWrap}>
					<Button
						disabled={userClosure}
						label={t('account.withdrawals.cancel.cancel')}
						onClick={handleCancelClosure}
					/>
				</div>
			</div>
		</div>
	);
}

ChildCancelClosure.propTypes = {
	child: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		lastName: PropTypes.string.isRequired,
	}).isRequired,
	reloadChildrenList: PropTypes.func.isRequired,
	reloadUser: PropTypes.func.isRequired,
	userClosure: PropTypes.bool.isRequired,
};
