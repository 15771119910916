import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes } from 'react-router-dom';

import { FooterCta } from '../../components/common';
import { Loader } from '../../components/ui';
import { useCountry, useLanguage } from '../../contexts/LocaleContext';
import { useSetSubpages } from '../../contexts/PageContext';
import { fetchWebflow } from '../../utils/api';
import childRoute from '../../utils/childRoute';
import { countryLanguages, slugRoute } from '../../utils/routes';
import useFetch from '../../utils/useFetch';
import processBlogPostData from '../../utils/webflow';
import Error404 from '../Error404';
import BlogList from './BlogList';
import BlogPost from './BlogPost';
import BlogPostBox from './BlogPost/BlogPostBox';
import BlogPostContent from './BlogPost/BlogPostContent';
import BlogPostTable from './BlogPost/BlogPostTable';

import posts from './posts';

const legacyPostsByLanguage = { CZ: { cs: posts } };
const legacyPostDataByLanguage = { CZ: { cs: posts.map(({ data }) => data) } };
const elementTags = {
	'***': { type: BlogPostBox },
	'***info': { type: BlogPostBox, props: { info: true }, closing: '***' },
	___: { type: BlogPostTable, raw: true },
	'```': { raw: true },
};

function useFetchWebflow(prefetchedData, country, languages) {
	const prefetchedDataFn = useCallback(() => Promise.resolve(prefetchedData), [prefetchedData]);
	return useFetch(prefetchedData ? prefetchedDataFn : fetchWebflow)(prefetchedData, country, languages);
}

function getSubpages(postsByLanguage, country, languages) {
	return languages.reduce((acc, language) => {
		const languageData = postsByLanguage[language].concat(legacyPostDataByLanguage[country]?.[language] ?? []);
		acc[language] = languageData.reduceRight((accumulator, post) => {
			accumulator[post.slug] = post.languageVersions;
			return accumulator;
		}, {});
		return acc;
	}, {});
}

export default function Blog({
	baseUrl,
	blogPostETFUrl,
	blogPostUrl,
	blogUrl,
	feesUrl,
	homepageUrl,
	howItWorksUrl,
	prefetchedData,
	registrationUrl,
}) {
	const country = useCountry();
	const language = useLanguage();
	const [addSubpages, removeSubpages] = useSetSubpages();
	const [t] = useTranslation();

	const languages = countryLanguages(country);
	const [webflowRawData, loading] = useFetchWebflow(prefetchedData, country, languages);
	const webflowPostsByLanguage = useMemo(
		() => webflowRawData && processBlogPostData(languages, webflowRawData, elementTags),
		[webflowRawData],
	);

	useEffect(() => {
		if (!webflowPostsByLanguage) {
			return undefined;
		}

		addSubpages(getSubpages(webflowPostsByLanguage, country, languages));
		return () => {
			removeSubpages();
		};
	}, [webflowPostsByLanguage, country, languages]);

	const webflowPosts = webflowPostsByLanguage?.[language] ?? [];
	const legacyPosts = legacyPostsByLanguage[country]?.[language] ?? [];
	const legacyPostData = legacyPostDataByLanguage[country]?.[language] ?? [];

	const metaTitle = `${t('blog.meta.title')}${t('meta.titleSuffix')}`;
	const metaImage = `${baseUrl}/images/blog/fondee-og-blog.png`;
	const metaHelmet = (
		<Helmet>
			<title>{metaTitle}</title>
			<meta content={t('blog.meta.description')} name="description" />
			<meta content={metaTitle} property="og:title" />
			<meta content={t('blog.meta.description')} property="og:description" />
			<meta content={metaImage} property="og:image" />
			<meta content={metaImage} name="twitter:image" />
		</Helmet>
	);

	if (!webflowPostsByLanguage && loading) {
		return (
			<>
				{metaHelmet}
				<Loader fullPage />
			</>
		);
	}

	return (
		<>
			{metaHelmet}
			<Routes>
				<Route
					element={(
						<>
							<Outlet />
							<FooterCta registrationUrl={registrationUrl} />
						</>
					)}
				>
					<Route
						element={(
							<BlogList
								blogPostUrl={({ slug }) => blogPostUrl(slug)}
								posts={webflowPosts.filter(({ published }) => published).concat(legacyPostData)}
							/>
						)}
						index
					/>
					{webflowPosts.map((post) => childRoute(post.id, slugRoute(post.slug), (
						<BlogPost
							blogUrl={blogUrl}
							date={post.date}
							metaDescription={post.metaDescription}
							metaImage={post.metaImage}
							perex={post.perex}
							perexRich={post.perexRich}
							shareUrl={`${baseUrl}${blogPostUrl(post.slug)}`}
							title={post.title}
							topic={post.topic}
							updated={post.updated}
						>
							{post.headings?.length > 0 && post.headingSlugs?.length > 0 && (
								<BlogPostContent
									content={post.headings}
									slugs={post.headingSlugs}
								/>
							)}
							{post.content}
						</BlogPost>
					)))}
					{legacyPosts.map(({ component: Component, data }) => childRoute(data.id, slugRoute(data.slug), (
						<Component
							baseUrl={baseUrl}
							blogPostETFUrl={blogPostETFUrl}
							blogUrl={blogUrl}
							feesUrl={feesUrl}
							homepageUrl={homepageUrl}
							howItWorksUrl={howItWorksUrl}
							shareUrl={`${baseUrl}${blogPostUrl(data.slug)}`}
						/>
					)))}
				</Route>
				<Route
					element={<Error404 homepageUrl={blogUrl} />}
					path="/*"
				/>
			</Routes>
		</>
	);
}

Blog.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	blogPostETFUrl: PropTypes.string,
	blogPostUrl: PropTypes.func.isRequired,
	blogUrl: PropTypes.string.isRequired,
	feesUrl: PropTypes.string.isRequired,
	homepageUrl: PropTypes.string.isRequired,
	howItWorksUrl: PropTypes.string.isRequired,
	prefetchedData: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object.isRequired).isRequired),
	registrationUrl: PropTypes.string.isRequired,
};

Blog.defaultProps = {
	blogPostETFUrl: null,
	prefetchedData: null,
};
