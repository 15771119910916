import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../../contexts/LocaleContext';
import { useUserContext } from '../../../contexts/UserContext';
import {
	cancelPortfolioWithdrawal,
	fetchProfilePortfolioWithdrawal,
	fetchProfilePortfolioWithdrawalPensionCheck,
	fetchProfilePortfolioWithdrawalUserAccountCheck,
} from '../../../utils/api';
import { useTrackGTMEvent } from '../../../utils/GTMProvider';
import { toNumber } from '../../../utils/number';
import useFetchAuth from '../../../utils/useFetchAuth';
import useFormatNumber from '../../../utils/useFormatNumber';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Accordion, Alert, Button } from '../../ui';
import PortfolioCancel from './PortfolioCancel';
import PortfolioTransfer from './PortfolioTransfer';
import PortfolioWithdrawal from './PortfolioWithdrawal';

import styles from './PortfolioWithdrawals.module.scss';

const useFetchPortfolioWithdrawal = useFetchAuth(fetchProfilePortfolioWithdrawal);
const useFetchPortfolioWithdrawalPensionCheck = useFetchAuth(fetchProfilePortfolioWithdrawalPensionCheck);
const useFetchPortfolioWithdrawalUserAccountCheck = useFetchAuth(fetchProfilePortfolioWithdrawalUserAccountCheck);

export default function PortfolioWithdrawals({
	accountUrl,
	currencies,
	portfolio,
	portfolios,
	portfolioTotalValue,
	portfolioWithdrawalsAnchor,
	setCheck,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const formatNumber = useFormatNumber();
	const cancelPortfolioWithdrawalAuth = useRequestAuth(cancelPortfolioWithdrawal);
	const [portfolioWithdrawalData, dataLoading, dataError] = useFetchPortfolioWithdrawal(
		null,
		portfolio.id,
		language,
	);
	const [portfolioWithdrawalPensionCheck] = useFetchPortfolioWithdrawalPensionCheck(null, portfolio);
	const [portfolioWithdrawalUserAccountCheck] = useFetchPortfolioWithdrawalUserAccountCheck(null, portfolio.id);
	const [user] = useUserContext();
	const trackGTMEvent = useTrackGTMEvent();

	if (user.account_closure || portfolio.child?.accountClosure) {
		return (
			<div id={portfolioWithdrawalsAnchor}>
				<h2 className={styles.title}>
					{t('account.withdrawals.cancel.portfolio.account.title')}
				</h2>
				<div className={styles.wrap}>
					<p className={styles.withdrawalText}>
						<Trans i18nKey={user.child ? 'account.withdrawals.cancel.portfolio.account.child.closure' : 'account.withdrawals.cancel.portfolio.account.closure'}>
							<Link to={`${accountUrl}#${t('anchors.accountClosure')}`} />
						</Trans>
					</p>
				</div>
			</div>
		);
	}

	if (dataLoading || dataError || currencies === null || portfolioWithdrawalUserAccountCheck === null) {
		return null;
	}

	const transferPortfolios = portfolio.child
		? portfolios.filter(({ child }) => portfolio.child.id === child?.id)
		: portfolios;

	const portfolioWithdrawalList = portfolioWithdrawalData
		? portfolioWithdrawalData.filter((item) => item.status < 3)
		: [];

	const portfolioWithdrawalPart = portfolioWithdrawalList.filter((item) => item.type === 1);
	const portfolioWithdrawalAll = portfolioWithdrawalList.filter((item) => item.type === 2);
	const portfolioWithdrawalCancel = portfolioWithdrawalList.filter((item) => item.type === 3);
	const portfolioTransferPart = portfolioWithdrawalList.filter((item) => item.type === 4);
	const portfolioTransferAll = portfolioWithdrawalList.filter((item) => item.type === 5);
	const portfolioTransferRatio = portfolioWithdrawalList.filter((item) => item.type === 6);

	const ratio = portfolioTransferRatio.length > 0
		? portfolioTransferRatio.reduce((acc, volume) => acc + volume.transferRatio, 0)
		: 0;

	const canCancel = portfolioWithdrawalList.filter((item) => item.closedChange).length === 0
		&& portfolioWithdrawalList.filter((item) => item.status === 0).length > 0;
	const onlyParentCanCancel = user.child
		&& portfolioWithdrawalList.filter((item) => item.createdById === +user.id).length === 0;
	const handleCancelWithdrawal = async () => {
		await cancelPortfolioWithdrawalAuth(portfolio.id);
		setCheck(true);
		trackGTMEvent('interactions', {
			eventCategory: 'interactions',
			eventAction: 'buttonClick',
			eventLabel: 'cancel_withdrawals',
		});
	};

	const cancelButton = (type) => (
		<div className={styles.cancelWrap}>
			<Button
				disabled={!canCancel || onlyParentCanCancel}
				label={t(`account.withdrawals.${type}.cancel`)}
				onClick={handleCancelWithdrawal}
				isFlat
				isSmall
				outline
			/>
		</div>
	);

	const cancelPart = (sum, transfer) => (
		<>
			<h2 className={styles.title}>
				{t('account.withdrawals.activeTitle')}
			</h2>
			<div className={styles.wrap}>
				<div>
					{sum && (
						<p className={styles.withdrawalText}>
							{t('account.withdrawals.withdrawals.part.activeText', { sum })}
						</p>
					)}
					{transfer.length > 1 && (
						<p className={styles.withdrawalText}>
							{t('account.withdrawals.withdrawals.part.activeTextTransferMultiple')}
						</p>
					)}
					{transfer.map(({ id, portfolioName, sumTransfer }) => (
						<p key={id} className={styles.withdrawalText}>
							{t(`account.withdrawals.withdrawals.part.${
								transfer.length > 1 ? 'activeTextTransferMultiplePart' : 'activeTextTransfer'
							}`, { portfolio: portfolioName, sumTransfer })}
						</p>
					))}
				</div>
				{cancelButton('withdrawals')}
			</div>
		</>
	);

	const cancelAll = (
		<>
			<h2 className={styles.title}>
				{t('account.withdrawals.activeTitle')}
			</h2>
			<div className={styles.wrap}>
				<p className={styles.withdrawalText}>
					{t('account.withdrawals.withdrawals.all.activeText')}
				</p>
				{cancelButton('withdrawals')}
			</div>
		</>
	);

	const cancelCancel = (
		<>
			<h2 className={styles.title}>
				{t('account.withdrawals.activeTitle')}
			</h2>
			<div className={styles.wrap}>
				<p className={styles.withdrawalText}>
					{t('account.withdrawals.cancel.portfolio.activeText')}
				</p>
				{cancelButton('cancel.portfolio')}
			</div>
		</>
	);

	const cancelAllTransfer = (portfolioName) => (
		<>
			<h2 className={styles.title}>
				{t('account.withdrawals.activeTitle')}
			</h2>
			<div className={styles.wrap}>
				<p className={styles.withdrawalText}>
					{t('account.withdrawals.transfer.all.activeText', { portfolio: portfolioName })}
				</p>
				{cancelButton('transfer')}
			</div>
		</>
	);

	const cancelPartTransfer = (data) => (
		<>
			<h2 className={styles.title}>
				{t('account.withdrawals.activeTitle')}
			</h2>
			<div className={styles.wrap}>
				<div>
					{data.map(({ id, portfolioName, sumRatio }) => (
						<p key={id} className={styles.withdrawalText}>
							{t('account.withdrawals.transfer.part.activeText', {
								portfolio: portfolioName,
								sum: formatNumber(sumRatio, { style: 'percent' }),
							})}
						</p>
					))}
				</div>
				{cancelButton('transfer')}
			</div>
		</>
	);

	return (
		<>
			<div id={portfolioWithdrawalsAnchor}>
				{portfolioWithdrawalCancel.length > 0 && cancelCancel}

				{portfolioTransferAll.length > 0 && cancelAllTransfer(portfolioTransferAll[0].transferToPortfolio.name)}

				{portfolioWithdrawalCancel.length === 0 && portfolioWithdrawalAll.length > 0 && cancelAll}

				{portfolioWithdrawalCancel.length === 0 && portfolioTransferRatio.length > 0 && cancelPartTransfer(
					Object.values(portfolioTransferRatio.reduce((accumulator, transfer) => {
						const { transferToPortfolio: { id, name }, transferRatio } = transfer;
						accumulator[id] = accumulator[id] ?? { id, portfolioName: name, sumRatio: 0 };
						accumulator[id].sumRatio += toNumber(transferRatio) || 0;
						return accumulator;
					}, {})),
				)}

				{portfolioWithdrawalAll.length === 0
					&& portfolioWithdrawalCancel.length === 0
					&& portfolioTransferAll.length === 0
					&& (portfolioWithdrawalPart.length > 0 || portfolioTransferPart.length > 0)
					&& cancelPart(
						Object.values(portfolioWithdrawalPart.reduce((accumulator, { currency, volume }) => {
							accumulator[currency.code] = accumulator[currency.code] ?? { currency, sum: 0 };
							accumulator[currency.code].sum += toNumber(volume) || 0;
							return accumulator;
						}, {})).map(({ currency, sum }) => (
							formatNumber(sum, { style: 'currency', currency: currency.code })
						)).join(', '),
						Object.values(portfolioTransferPart.reduce((accumulator, transfer) => {
							const { transferToPortfolio: { id, name }, currency, volume } = transfer;
							accumulator[id] = accumulator[id] ?? { id, portfolioName: name, sums: {} };
							accumulator[id].sums[currency.code] = accumulator[id].sums[currency.code]
								?? { currency, sum: 0 };
							accumulator[id].sums[currency.code].sum += toNumber(volume) || 0;
							return accumulator;
						}, {})).map(({ id, portfolioName, sums }) => ({
							id,
							portfolioName,
							sumTransfer: Object.values(sums).map(({ currency, sum }) => (
								formatNumber(sum, { style: 'currency', currency: currency.code })
							)).join(', '),
						})),
					)}

				{user.child && portfolioWithdrawalList.filter((item) => item.createdById !== +user.id).length > 0 && (
					<div className={styles.alertWrap}>
						<Alert type="danger">
							{t('account.withdrawals.withdrawals.cancelOnlyParent')}
						</Alert>
					</div>
				)}

				{portfolioWithdrawalAll.length === 0
					&& portfolioWithdrawalCancel.length === 0
					&& portfolioTransferAll.length === 0
					&& portfolioTransferRatio.length === 0
					&& (
						<Accordion
							disabled={
								!portfolioWithdrawalUserAccountCheck.volume
								&& !portfolioWithdrawalUserAccountCheck.all
							}
							disabledMessage="account.withdrawals.withdrawals.disabledMessage"
							title="account.withdrawals.withdrawals.title"
						>
							<PortfolioWithdrawal
								currencies={currencies}
								disabledAll={!portfolioWithdrawalUserAccountCheck.all}
								disabledVolume={!portfolioWithdrawalUserAccountCheck.volume}
								pensionCheck={portfolioWithdrawalPensionCheck}
								portfolio={portfolio}
								setCheck={setCheck}
							/>
						</Accordion>
					)}

				{portfolio.categoryName !== 'pension'
					&& transferPortfolios.length > 1
					&& portfolioTotalValue > 0
					&& portfolioWithdrawalAll.length === 0
					&& portfolioWithdrawalCancel.length === 0
					&& portfolioTransferAll.length === 0
					&& ratio < 1
					&& (
						<Accordion title="account.withdrawals.transfer.title">
							<PortfolioTransfer
								portfolio={portfolio}
								portfolios={transferPortfolios}
								portfolioTransferPart={portfolioTransferPart}
								portfolioTransferRatio={portfolioTransferRatio}
								portfolioWithdrawalPart={portfolioWithdrawalPart}
								setCheck={setCheck}
							/>
						</Accordion>
					)}

				{portfolioWithdrawalCancel.length === 0
					&& portfolioTransferAll.length === 0
					&& ratio < 1
					&& (
						<Accordion
							disabled={!portfolioWithdrawalUserAccountCheck.allAndClosePortfolio}
							disabledMessage={
								user.child
									? 'account.withdrawals.cancel.portfolio.disabledMessage'
									: 'account.withdrawals.withdrawals.disabledMessage'
							}
							title="account.withdrawals.cancel.portfolio.title"
						>
							<PortfolioCancel
								currencies={currencies}
								pensionCheck={portfolioWithdrawalPensionCheck}
								portfolio={portfolio}
								setCheck={setCheck}
							/>
						</Accordion>
					)}
			</div>
		</>
	);
}

PortfolioWithdrawals.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	portfolio: PropTypes.shape({
		categoryName: PropTypes.string.isRequired,
		child: PropTypes.shape({
			accountClosure: PropTypes.bool.isRequired,
			id: PropTypes.number.isRequired,
		}),
		currency: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
	}).isRequired,
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		categoryName: PropTypes.string.isRequired,
		child: PropTypes.shape({
			firstName: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
		}),
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired).isRequired,
	portfolioTotalValue: PropTypes.number,
	portfolioWithdrawalsAnchor: PropTypes.string.isRequired,
	setCheck: PropTypes.func.isRequired,
};

PortfolioWithdrawals.defaultProps = {
	currencies: null,
	portfolioTotalValue: null,
};
