import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useCountry } from '../../../../contexts/LocaleContext';
import { filterChild, useUserContext } from '../../../../contexts/UserContext';
import {
	portfolioWithdrawal,
	portfolioWithdrawalRatio,
	portfolioWithdrawalVolume,
} from '../../../../utils/api';
import { useTrackGTMEvent } from '../../../../utils/GTMProvider';
import { toInt } from '../../../../utils/number';
import useFormatNumber from '../../../../utils/useFormatNumber';
import useRequestAuth from '../../../../utils/useRequestAuth';
import {
	RadioList,
	Ranger,
	SelectField,
	TextField,
} from '../../../forms';
import { Container } from '../../../layout';
import {
	Alert,
	Badge,
	Button,
	Modal,
} from '../../../ui';

import styles from '../PortfolioWithdrawals.module.scss';

const CASH_MIN = 10;
const CASH_MAX = 100;

export default function PortfolioTransfer({
	portfolio,
	portfolios,
	portfolioTransferPart,
	portfolioTransferRatio,
	portfolioWithdrawalPart,
	setCheck,
}) {
	const [confirm, setConfirm] = useState(false);
	const [confirmVolume, setConfirmVolume] = useState(false);
	const [volumeError, setVolumeError] = useState(false);
	const [ratioError, setRatioError] = useState(false);
	const [withdrawalError, setWithdrawalError] = useState(false);
	const [orderError, setOrderError] = useState(false);
	const [has2fa, setHas2fa] = useState(false);
	const portfolioWithdrawalAuth = useRequestAuth(portfolioWithdrawal);
	const portfolioWithdrawalVolumeAuth = useRequestAuth(portfolioWithdrawalVolume);
	const portfolioWithdrawalRatioAuth = useRequestAuth(portfolioWithdrawalRatio);
	const [t] = useTranslation();
	const country = useCountry();
	const formatPercent = useFormatNumber({ style: 'percent' });
	const trackGTMEvent = useTrackGTMEvent();
	const [user] = useUserContext();

	const currencyOptions = [{
		label: 'EUR',
		value: 'EUR',
	}];

	const portfolioOptions = portfolios.filter(({ id }) => id !== portfolio.id).map((item) => ({
		label: (
			<>
				<img
					src={`${process.env.PUBLIC_URL}/images/faces/${item.faceImageFile}`}
					alt={item.name}
					width={40}
					height={40}
				/>
				{filterChild(item.child, user) && (
					<span className={styles.badgeWrap}>
						<Badge label={item.child.firstName} size="tiny" variant="orange" />
					</span>
				)}
				{item.categoryName === 'pension' && (
					<span className={styles.badgeWrap}>
						<Badge label={t('productSelect.pension')} size="tiny" variant="wine" />
						{country === 'CZ' && <Badge label="DIP" size="tiny" variant="wine" />}
					</span>
				)}
				<span>{item.name}</span>
			</>
		),
		value: item.id,
	}));

	const checkVolume = async (currency, volume) => {
		setVolumeError(false);
		try {
			const result = await portfolioWithdrawalVolumeAuth(
				portfolio.id,
				currency,
				volume,
			);
			if (result.success && result.warning === null) {
				setConfirm(true);
			} else if (result.warning === 'almostTotalValue') {
				setConfirmVolume(true);
			} else if (result.warning === 'overTotalValue') {
				setVolumeError(true);
			}
		} catch (e) {
			const errorMessage = e.responseJson?.message;
			if (typeof errorMessage === 'string') {
				if (errorMessage.indexOf('This type of withdrawal cannot be created now') !== -1) {
					setOrderError(true);
				}
			}
			setWithdrawalError(true);
		}
	};

	const checkRatio = async (transferRatio) => {
		setRatioError(false);
		try {
			const result = await portfolioWithdrawalRatioAuth(
				portfolio.id,
				transferRatio,
			);
			if (result.success && result.warning === null) {
				setConfirm(true);
			} else if (result.warning === 'overTotalValue') {
				setRatioError(true);
			}
		} catch (e) {
			const errorMessage = e.responseJson?.message;
			if (typeof errorMessage === 'string') {
				if (errorMessage.indexOf('This type of withdrawal cannot be created now') !== -1) {
					setOrderError(true);
				}
			}
			setWithdrawalError(true);
		}
	};

	const checkChildPortfolio = (portfolioId) => {
		const childPortfolio = portfolios.find((item) => item.id === portfolioId);
		if (childPortfolio) {
			return filterChild(childPortfolio.child, user);
		}
		return false;
	};

	return (
		<Container>
			<div>
				<Formik
					initialValues={{
						portfolio: null,
						twoFactorCode: '',
						type: portfolioTransferRatio.length > 0 ? 6 : 4,
						volume: '',
						currency: 'EUR',
						ranger: 10,
					}}
					onSubmit={async (values, { setErrors, setFieldValue }) => {
						const volume = values.type === 4 ? toInt(values.volume) || 0 : null;
						const ratio = values.type === 6 ? toInt(values.ranger) || 0 : null;
						let show2fa = false;
						setWithdrawalError(false);
						try {
							await portfolioWithdrawalAuth(
								portfolio.id,
								values.type,
								volume,
								has2fa ? values.twoFactorCode : null,
								values.currency,
								ratio,
								values.portfolio,
							);
						} catch (e) {
							const fieldErrors = {};
							const errorMessage = e.responseJson?.message;
							if (typeof errorMessage === 'string') {
								if (errorMessage.indexOf('higher than the current value of the portfolio') !== -1) {
									fieldErrors.volume = 'account.withdrawals.withdrawals.part.overError';
									setFieldValue('twoFactorCode', '', false);
									setHas2fa(false);
									setConfirm(false);
								} else if (errorMessage.indexOf('Two factor authentication code needed') !== -1) {
									setHas2fa(true);
									show2fa = true;
								} else if (errorMessage.indexOf('Bad two factor authentication code') !== -1) {
									fieldErrors.twoFactorCode = 'forms.twoFactorAuthentication.error';
								} else if (errorMessage.indexOf('This type of withdrawal cannot be created now') !== -1) {
									setOrderError(true);
									setConfirm(false);
								}
							}

							const hasFieldErrors = Object.keys(fieldErrors).length > 0;
							if (hasFieldErrors) {
								setErrors(fieldErrors);
							}
							setWithdrawalError(!hasFieldErrors && !show2fa);
							return;
						}
						trackGTMEvent('interactions', {
							eventCategory: 'interactions',
							eventAction: 'buttonClick',
							eventLabel: 'insert_withdrawal_transfer',
						});
						setHas2fa(false);
						setConfirm(false);
						setConfirmVolume(false);
						setCheck(true);
					}}
				>
					{({
						handleSubmit,
						values,
						setFieldValue,
						errors,
						touched,
						handleBlur,
						handleChange,
						isSubmitting,
					}) => (
						<form onSubmit={handleSubmit}>
							{filterChild(portfolio.child, user) && (
								<Alert
									autoScroll={false}
									type="warning"
								>
									{t('account.withdrawals.transfer.childWarning')}
								</Alert>
							)}
							{portfolioTransferPart.length > 0 && (
								<Alert type="warning">
									{t('account.withdrawals.transfer.direct.transferWarning')}
								</Alert>
							)}
							{portfolioTransferRatio.length > 0 && (
								<Alert type="warning">
									{t('account.withdrawals.transfer.part.transferWarning')}
								</Alert>
							)}
							<div className={styles.input}>
								<SelectField
									id="portfolio"
									name="portfolio"
									label={t('account.withdrawals.transfer.portfolioLabel')}
									options={portfolioOptions}
									onChange={setFieldValue}
									value={values.portfolio}
									required
								/>
							</div>
							<div className={styles.input}>
								<SelectField
									id="type"
									name="type"
									label={t('account.withdrawals.transfer.title')}
									isLabelHidden
									options={[{
										label: t('account.withdrawals.transfer.direct.title'),
										value: 4,
										disabled: portfolioTransferRatio.length > 0,
									}, {
										label: t('account.withdrawals.transfer.all.title'),
										value: 5,
										disabled: portfolioTransferRatio.length > 0,
									}, {
										label: t('account.withdrawals.transfer.part.title'),
										value: 6,
										disabled: portfolioWithdrawalPart.length > 0
											|| portfolioTransferPart.length > 0,
									}]}
									onChange={setFieldValue}
									value={values.type}
								/>
							</div>
							{values.type === 4 && (
								<>
									<div className={styles.input}>
										<TextField
											onBlur={handleBlur}
											onChange={handleChange}
											error={(
												errors.volume
													&& touched.volume
													&& t(errors.volume)
											) || (
												volumeError
													? t('account.withdrawals.withdrawals.part.overError')
													: ''
											)}
											id="volume"
											name="volume"
											label="volume"
											minLength={1}
											maxLength={13}
											isLabelHidden
											required
											type="text"
											pattern="[0-9]+"
											value={values.volume}
										/>
										<div className={styles.currencySelect}>
											<RadioList
												name="currency"
												id="transferCurrency"
												onChange={handleChange}
												options={currencyOptions}
												value={values.currency}
											/>
										</div>
									</div>
									<p className={styles.text}>
										{t('account.withdrawals.transfer.direct.text')}
									</p>
								</>
							)}
							{values.type === 5 && (
								<>
									<RadioList
										name="currency"
										id="transferCurrency"
										onChange={handleChange}
										options={currencyOptions}
										value={values.currency}
									/>
									<p className={styles.text}>
										{t('account.withdrawals.transfer.all.text')}
									</p>
								</>
							)}
							{values.type === 6 && (
								<>
									<div className={styles.input}>
										<Ranger
											min={CASH_MIN}
											minLabel={formatPercent(CASH_MIN / 100)}
											max={CASH_MAX}
											maxLabel={formatPercent(CASH_MAX / 100)}
											id="ranger"
											name="ranger"
											onChange={setFieldValue}
											step={10}
											value={values.ranger}
										/>
									</div>
									<p className={styles.text}>
										{t('account.withdrawals.transfer.part.text')}
									</p>
								</>
							)}
							<Modal
								isVisible={confirmVolume}
								onClose={() => {
									setFieldValue('twoFactorCode', '', false);
									setConfirmVolume(false);
									setHas2fa(false);
								}}
								title={t('account.withdrawals.transfer.all.allPopup.title')}
							>
								<div className={styles.modal}>
									{withdrawalError && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									<p className={styles.modalText}>
										{t('account.withdrawals.transfer.all.allPopup.text')}
									</p>
									{has2fa && (
										<>
											<TextField
												autocomplete="one-time-code"
												autoFocus={has2fa}
												onBlur={handleBlur}
												onChange={handleChange}
												error={
													errors.twoFactorCode
														&& touched.twoFactorCode
														&& t(errors.twoFactorCode)
												}
												id="twoFactorCode"
												name="twoFactorCode"
												label={t('account.withdrawals.withdrawals.confirmPopup.twoFactorAuthentication')}
												required={has2fa}
												type="text"
												inputMode="numeric"
												pattern="[0-9]*"
												value={values.twoFactorCode}
											/>
											<Button
												disabled={isSubmitting}
												isSubmit
												label={t('account.withdrawals.transfer.confirm')}
											/>
										</>
									)}
									{!has2fa && (
										<>
											<Button
												disabled={isSubmitting}
												isSubmit={confirmVolume}
												onClick={() => {
													setFieldValue('type', 5);
												}}
												label={t('account.withdrawals.transfer.all.allPopup.button')}
											/>
											<Button
												disabled={isSubmitting}
												isSubmit={confirmVolume}
												isLink
												label={t('account.withdrawals.transfer.all.allPopup.secondaryButton')}
											/>
										</>
									)}
								</div>
							</Modal>
							<Modal
								isVisible={confirm}
								onClose={() => {
									setFieldValue('twoFactorCode', '', false);
									setConfirm(false);
									setHas2fa(false);
								}}
								title={t('account.withdrawals.transfer.popup.title')}
							>
								<div className={styles.modal}>
									<p className={styles.modalText}>
										{t(checkChildPortfolio(values.portfolio) ? 'account.withdrawals.transfer.popup.textChild' : 'account.withdrawals.transfer.popup.text')}
									</p>
									{withdrawalError && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									{has2fa && (
										<TextField
											autocomplete="one-time-code"
											autoFocus={has2fa}
											onBlur={handleBlur}
											onChange={handleChange}
											error={
												errors.twoFactorCode
													&& touched.twoFactorCode
													&& t(errors.twoFactorCode)
											}
											id="twoFactorCode"
											name="twoFactorCode"
											label={t('account.withdrawals.transfer.popup.twoFactorAuthentication')}
											required={has2fa}
											type="text"
											inputMode="numeric"
											pattern="[0-9]*"
											value={values.twoFactorCode}
										/>
									)}
									<Button
										disabled={isSubmitting}
										isSubmit={confirm}
										label={t('account.withdrawals.transfer.popup.button')}
									/>
								</div>
							</Modal>
							<Modal
								isVisible={orderError}
								onClose={() => {
									setOrderError(false);
								}}
								title={t('account.withdrawals.transfer.error.title')}
							>
								<div className={styles.modal}>
									<p className={styles.modalText}>
										{t('account.withdrawals.transfer.error.text')}
									</p>
									<Button
										disabled={isSubmitting}
										label={t('account.withdrawals.transfer.error.button')}
										onClick={() => setOrderError(false)}
									/>
								</div>
							</Modal>
							<Modal
								isVisible={ratioError}
								onClose={() => {
									setRatioError(false);
								}}
								title={t('account.withdrawals.transfer.part.error.title')}
							>
								<div className={styles.modal}>
									<p className={styles.modalText}>
										{t('account.withdrawals.transfer.part.error.text')}
									</p>
									<Button
										disabled={isSubmitting}
										label={t('account.withdrawals.transfer.part.error.button')}
										onClick={() => setRatioError(false)}
									/>
								</div>
							</Modal>
							<Button
								disabled={
									isSubmitting
									|| (values.type === 4 && (toInt(values.volume) || 0) <= 0)
									|| (values.portfolio === null)
								}
								isSubmit={!confirm}
								label={t('account.withdrawals.transfer.confirm')}
								onClick={(e) => {
									e.preventDefault();
									if (values.type === 4) {
										checkVolume(
											values.currency,
											values.volume,
										);
									} else if (values.type === 6) {
										checkRatio(
											values.ranger,
										);
									} else {
										setConfirm(true);
									}
								}}
							/>
						</form>
					)}
				</Formik>
			</div>
		</Container>
	);
}

PortfolioTransfer.propTypes = {
	portfolio: PropTypes.shape({
		child: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		currency: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
	}).isRequired,
	portfolios: PropTypes.arrayOf(PropTypes.shape({
		categoryName: PropTypes.string.isRequired,
		child: PropTypes.shape({
			firstName: PropTypes.string.isRequired,
			id: PropTypes.number.isRequired,
		}),
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired).isRequired,
	portfolioTransferPart: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
	}).isRequired).isRequired,
	portfolioTransferRatio: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
	}).isRequired).isRequired,
	portfolioWithdrawalPart: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
	}).isRequired).isRequired,
	setCheck: PropTypes.func.isRequired,
};
