import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useCountry } from '../../../contexts/LocaleContext';
import {
	cancelPhoneNumberChange,
	saveProfilePersonalInformation,
	sendPhoneChangeCode,
	validatePhoneNumberChange,
} from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';
import {
	checkStreetName,
	checkStreetNumber,
	validateCode,
	validatePhoneWarning,
	validatePersonalInformation,
} from '../../../utils/validators';
import {
	CheckboxField,
	DobField,
	PhoneField,
	PostalCodeField,
	TextField,
} from '../../forms';
import { Col, Row } from '../../layout';
import {
	Alert,
	Button,
	EmailLink,
} from '../../ui';

import styles from './PersonalInformationForm.module.scss';

export default function PersonalInformationForm({
	childId,
	reloadUser,
	setShowNameModal,
	user,
}) {
	const [addressChange, setAddressChange] = useState(false);
	const [codeError, setCodeError] = useState(false);
	const [codeSendError, setCodeSendError] = useState(false);
	const [codeSendSuccess, setCodeSendSuccess] = useState(false);
	const [disabledSms, setDisabledSms] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [t] = useTranslation();
	const country = useCountry();
	const cancelPhoneNumberChangeAuth = useRequestAuth(cancelPhoneNumberChange);
	const codeActive = user?.phoneNumberPlanned || user?.phonePrefixPlanned;
	const saveProfilePersonalInformationAuth = useRequestAuth(saveProfilePersonalInformation);
	const smsTimeoutIdRef = useRef(null);
	const validatePhoneNumberChangeAuth = useRequestAuth(validatePhoneNumberChange);

	if (user === null) {
		return null;
	}

	useEffect(() => () => {
		if (smsTimeoutIdRef.current !== null) {
			clearTimeout(smsTimeoutIdRef.current);
			smsTimeoutIdRef.current = null;
		}
	}, []);

	const initialValues = {
		firstName: user.firstName,
		lastName: user.lastName,
		phonePrefix: user.phonePrefix ?? {
			CZ: '+420',
			PL: '+48',
			SK: '+421',
		}[country] ?? '+420',
		phoneNumber: user.phoneNumber ?? '',
		streetName: user.streetName ?? '',
		streetNumber: user.streetNumber ?? '',
		city: user.city ?? '',
		postalCode: user.postalCode ?? '',
		contactAddressStreetName: user.contactAddressStreetName ?? '',
		contactAddressStreetNumber: user.contactAddressStreetNumber ?? '',
		contactAddressCity: user.contactAddressCity ?? '',
		contactAddressPostalCode: user.contactAddressPostalCode ?? '',
		confirmAddressChange: false,
	};
	const phoneRequired = user.phonePrefix !== null && user.phoneNumber !== null;

	const resetSmsTimeout = () => {
		setDisabledSms(true);

		if (smsTimeoutIdRef.current !== null) {
			clearTimeout(smsTimeoutIdRef.current);
		}

		smsTimeoutIdRef.current = setTimeout(() => {
			if (smsTimeoutIdRef.current !== null) {
				smsTimeoutIdRef.current = null;
				setDisabledSms(false);
			}
		}, 60000);
	};

	return (
		<div className={styles.wrap}>
			{!codeActive ? (
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validate={(values) => validatePersonalInformation(values, country, phoneRequired)}
					onSubmit={async (values, { setErrors }) => {
						setError(false);
						setSuccess(false);
						resetSmsTimeout();

						try {
							const { nameChange } = await saveProfilePersonalInformationAuth(
								values.firstName,
								values.lastName,
								values.phonePrefix,
								values.phoneNumber,
								values.streetName,
								values.streetNumber,
								values.city,
								values.postalCode,
								values.contactAddressStreetName,
								values.contactAddressStreetNumber,
								values.contactAddressCity,
								values.contactAddressPostalCode,
								childId,
							);

							reloadUser();
							setSuccess(true);
							if (nameChange) {
								setShowNameModal(true);
							}
						} catch (e) {
							const fieldErrors = {};
							const errorMessage = e.responseJson?.message;
							if (typeof errorMessage === 'string') {
								if (errorMessage.indexOf('phone prefix validity') !== -1) {
									fieldErrors.phoneNumber = 'forms.fields.phoneNumber.invalid';
								}
							}

							const hasFieldErrors = Object.keys(fieldErrors).length > 0;
							if (hasFieldErrors) {
								setErrors(fieldErrors);
							}
							setError(!hasFieldErrors);
						}
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						setFieldValue,
						touched,
						values,
					}) => (
						<form onSubmit={handleSubmit}>
							{error && (
								<Alert type="danger">
									{t('forms.error')}
								</Alert>
							)}
							<TextField
								onBlur={handleBlur}
								onChange={handleChange}
								error={
									errors.firstName
									&& touched.firstName
									&& t(errors.firstName)
								}
								id="firstName"
								name="firstName"
								label={t('forms.fields.firstName.label')}
								required
								type="text"
								value={values.firstName}
							/>
							<TextField
								onBlur={handleBlur}
								onChange={handleChange}
								error={
									errors.lastName
									&& touched.lastName
									&& t(errors.lastName)
								}
								id="lastName"
								name="lastName"
								label={t('forms.fields.lastName.label')}
								required
								type="text"
								value={values.lastName}
							/>
							<div className={styles.addressWrap}>
								<h2 className={styles.addressTitle}>
									{t('forms.fields.contactAddress.label')}
								</h2>
								<Row>
									<Col xs={8}>
										<TextField
											onBlur={handleBlur}
											onChange={handleChange}
											error={
												errors.contactAddressStreetName
												&& touched.contactAddressStreetName
												&& t(errors.contactAddressStreetName)
											}
											id="contactAddressStreetName"
											name="contactAddressStreetName"
											label={t('forms.fields.streetName.label')}
											required
											type="text"
											value={values.contactAddressStreetName}
											warning={
												touched.contactAddressStreetName
												&& !errors.contactAddressStreetName
												&& !checkStreetName(values.contactAddressStreetName, country)
													? t('forms.fields.streetName.warning')
													: ''
											}
										/>
									</Col>
									<Col xs={4}>
										<TextField
											onBlur={handleBlur}
											onChange={handleChange}
											error={
												errors.contactAddressStreetNumber
												&& touched.contactAddressStreetNumber
												&& t(errors.contactAddressStreetNumber)
											}
											id="contactAddressStreetNumber"
											name="contactAddressStreetNumber"
											label={t('forms.fields.streetNumber.label')}
											required
											type="text"
											value={values.contactAddressStreetNumber}
											warning={
												touched.contactAddressStreetNumber
												&& !errors.contactAddressStreetNumber
												&& !checkStreetNumber(values.contactAddressStreetNumber, country)
													? t('forms.fields.streetNumber.warning')
													: ''
											}
										/>
									</Col>
								</Row>
								<Row>
									<Col xs={8}>
										<TextField
											onBlur={handleBlur}
											onChange={handleChange}
											error={
												errors.contactAddressCity
												&& touched.contactAddressCity
												&& t(errors.contactAddressCity)
											}
											id="contactAddressCity"
											name="contactAddressCity"
											label={t('forms.fields.city.label')}
											required
											type="text"
											value={values.contactAddressCity}
										/>
									</Col>
									<Col xs={4}>
										<PostalCodeField
											country={country}
											onBlur={handleBlur}
											onChange={setFieldValue}
											error={
												errors.contactAddressPostalCode
												&& touched.contactAddressPostalCode
												&& t(errors.contactAddressPostalCode)
											}
											id="contactAddressPostalCode"
											name="contactAddressPostalCode"
											label={t('forms.fields.postalCode.label')}
											required
											type="text"
											value={values.contactAddressPostalCode}
										/>
									</Col>
								</Row>
								<a
									href="#addressChange"
									title={t('forms.fields.addressChange.change')}
									className={styles.addressChange}
									onClick={(e) => {
										e.preventDefault();
										setAddressChange(!addressChange);
									}}
								>
									{t('forms.fields.addressChange.change')}
								</a>
								<div className={`${styles.addressChangeWrap} ${addressChange ? styles.isOpen : ''}`.trim()}>
									<h2 className={styles.addressTitle}>
										{t('forms.fields.address.change')}
									</h2>
									<Row>
										<Col xs={8}>
											<TextField
												onBlur={handleBlur}
												onChange={handleChange}
												error={
													errors.streetName
													&& touched.streetName
													&& t(errors.streetName)
												}
												id="streetName"
												name="streetName"
												label={t('forms.fields.streetName.label')}
												required
												type="text"
												value={values.streetName}
												warning={
													touched.streetName
													&& !errors.streetName
													&& !checkStreetName(values.streetName, country)
														? t('forms.fields.streetName.warning')
														: ''
												}
											/>
										</Col>
										<Col xs={4}>
											<TextField
												onBlur={handleBlur}
												onChange={handleChange}
												error={
													errors.streetNumber
													&& touched.streetNumber
													&& t(errors.streetNumber)
												}
												id="streetNumber"
												name="streetNumber"
												label={t('forms.fields.streetNumber.label')}
												required
												type="text"
												value={values.streetNumber}
												warning={
													touched.streetNumber
													&& !errors.streetNumber
													&& !checkStreetNumber(values.streetNumber, country)
														? t('forms.fields.streetNumber.warning')
														: ''
												}
											/>
										</Col>
									</Row>
									<Row>
										<Col xs={8}>
											<TextField
												onBlur={handleBlur}
												onChange={handleChange}
												error={
													errors.city
													&& touched.city
													&& t(errors.city)
												}
												id="city"
												name="city"
												label={t('forms.fields.city.label')}
												required
												type="text"
												value={values.city}
											/>
										</Col>
										<Col xs={4}>
											<PostalCodeField
												country={country}
												onBlur={handleBlur}
												onChange={setFieldValue}
												error={
													errors.postalCode
													&& touched.postalCode
													&& t(errors.postalCode)
												}
												id="postalCode"
												name="postalCode"
												label={t('forms.fields.postalCode.label')}
												required
												type="text"
												value={values.postalCode}
											/>
										</Col>
									</Row>
									<CheckboxField
										onBlur={handleBlur}
										onChange={handleChange}
										checked={values.confirmAddressChange}
										error={
											errors.confirmAddressChange
											&& touched.confirmAddressChange
											&& t(errors.confirmAddressChange)
										}
										label={t('forms.fields.addressChange.confirm')}
										id="confirmAddressChange"
										name="confirmAddressChange"
										required={addressChange}
									/>
								</div>
							</div>
							<PhoneField
								error={
									(
										errors.phonePrefix
										&& touched.phonePrefix
										&& t(errors.phonePrefix)
									) || (
										errors.phoneNumber
										&& touched.phoneNumber
										&& t(errors.phoneNumber)
									)
								}
								helper={t('forms.fields.phoneNumber.helper')}
								id="phoneNumber"
								label={t('forms.fields.phoneNumber.label')}
								name="phoneNumber"
								onBlur={handleBlur}
								onChange={setFieldValue}
								phonePrefixId="phonePrefix"
								phonePrefixName="phonePrefix"
								phonePrefixValue={values.phonePrefix}
								required={phoneRequired}
								value={values.phoneNumber}
								warning={
									values.phoneNumber !== '' && !validatePhoneWarning(values.phonePrefix, values.phoneNumber)
										? t('forms.fields.phoneNumber.warning') : ''
								}
							/>
							<TextField
								disabled
								id="email"
								label={t('forms.fields.email.label')}
								name="email"
								type="text"
								readonly
								value={user.email ?? ''}
							/>
							<DobField
								disabled
								id="birthDate"
								label={t('forms.fields.birthDate.label')}
								name="birthDate"
								readonly
								value={user.birthDate ?? ''}
							/>
							{success && (
								<Alert
									autoClose
									close
									type="success"
									onClose={() => setSuccess(false)}
								>
									{t('forms.success')}
								</Alert>
							)}
							<Button
								label={t('account.personalData.button')}
								disabled={isSubmitting}
								isSubmit
							/>
						</form>
					)}
				</Formik>
			) : (
				<div className={`${styles.phoneWrap} ${codeActive ? styles.visible : styles.hidden}`.trim()}>
					<Formik
						initialValues={{
							code: '',
						}}
						validate={(values) => validateCode(values)}
						onSubmit={async (values) => {
							setCodeError(false);
							setError(false);

							try {
								await validatePhoneNumberChangeAuth(values.code, childId);
								reloadUser();
							} catch (e) {
								const errorMessage = e.responseJson?.message;
								if (typeof errorMessage === 'string' && errorMessage.indexOf('Bad SMS code') !== -1) {
									setCodeError(true);
								} else {
									setError(true);
								}
							}
						}}
					>
						{({
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							values,
							errors,
							touched,
						}) => (
							<form onSubmit={handleSubmit}>
								{error && (
									<Alert type="danger">
										{t('forms.error')}
									</Alert>
								)}
								{codeSendError && (
									<Alert type="danger">
										{t('account.personalData.phoneChange.codeSendError')}
									</Alert>
								)}
								{codeSendSuccess && (
									<Alert type="success">
										{t('account.personalData.phoneChange.codeSendSuccess')}
									</Alert>
								)}
								<Row middle>
									<Col>
										<p className={styles.phoneTitle}>
											{t('account.personalData.phoneChange.title')}
										</p>
									</Col>
									<Col lg={6}>
										<TextField
											onBlur={handleBlur}
											onChange={handleChange}
											error={
												errors.code
												&& touched.code
												&& t(errors.code)
											}
											id="code"
											name="code"
											label={t('onboarding.steps.agreement.formCode.code.label')}
											required
											type="text"
											value={values.code}
										/>
										{codeError && (
											<Alert type="danger">
												<Trans i18nKey="onboarding.steps.agreement.formCode.error">
													<EmailLink />
												</Trans>
											</Alert>
										)}
									</Col>
									<Col lg={6}>
										<Button
											label={t('account.personalData.phoneChange.confirm')}
											isSubmit
											disabled={isSubmitting}
										/>
									</Col>
									<Col lg={12}>
										<p className={styles.phoneText}>
											<Trans i18nKey="account.personalData.phoneChange.confirmText">
												<strong />
												{{ phoneNumber: `${user.phonePrefixPlanned} ${user.phoneNumberPlanned}` }}
											</Trans>
										</p>
										<p className={styles.phoneText}>
											<Trans i18nKey="account.personalData.phoneChange.cancelText">
												<a
													href="#phoneChange"
													onClick={(e) => {
														e.preventDefault();
														cancelPhoneNumberChangeAuth(childId).then(() => {
															reloadUser();
														});
													}}
												>
													{t('account.personalData.phoneChange.cancelText')}
												</a>
											</Trans>
										</p>
									</Col>
									<Col lg={6}>
										<Button
											disabled={isSubmitting || disabledSms}
											label={t('account.personalData.phoneChange.sendAgain')}
											onClick={async () => {
												setCodeError(false);
												setCodeSendError(false);
												setCodeSendSuccess(false);
												setError(false);
												resetSmsTimeout();

												try {
													const { success: codeSent } = await sendPhoneChangeCode(childId);

													if (codeSent) {
														setCodeSendSuccess(true);
													} else {
														setCodeSendError(true);
													}
												} catch {
													setError(true);
												}
											}}
										/>
									</Col>
								</Row>
							</form>
						)}
					</Formik>
				</div>
			)}
		</div>
	);
}

PersonalInformationForm.propTypes = {
	childId: PropTypes.number,
	reloadUser: PropTypes.func.isRequired,
	setShowNameModal: PropTypes.func.isRequired,
	user: PropTypes.shape({
		birthDate: PropTypes.string,
		city: PropTypes.string,
		contactAddressCity: PropTypes.string,
		contactAddressPostalCode: PropTypes.string,
		contactAddressStreetName: PropTypes.string,
		contactAddressStreetNumber: PropTypes.string,
		email: PropTypes.string,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		phoneNumber: PropTypes.string,
		phoneNumberPlanned: PropTypes.string,
		phonePrefix: PropTypes.string,
		phonePrefixPlanned: PropTypes.string,
		postalCode: PropTypes.string,
		streetName: PropTypes.string,
		streetNumber: PropTypes.string,
	}),
};

PersonalInformationForm.defaultProps = {
	childId: null,
	user: null,
};
