import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Badge, Picture } from '../../../../components/ui';
import { useLanguage } from '../../../../contexts/LocaleContext';
import useWindowSize from '../../../../utils/useWindowSize';
import isScreenWidthUp from '../../../../utils/isScreenWidthUp';

import styles from './BlogPostPreview.module.scss';

const PEREX_LENGTH_LG = 190;
const PEREX_LENGTH_XL = 480;

export default function BlogPostPreview({
	date,
	perex,
	previewImage,
	title,
	topic,
	updated,
	url,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const windowSize = useWindowSize();

	let perexTruncated;
	if (isScreenWidthUp(windowSize.width, 'xl') && perex.length > PEREX_LENGTH_XL) {
		perexTruncated = `${perex.substring(0, PEREX_LENGTH_XL)}\u2026`;
	} else if (isScreenWidthUp(windowSize.width, 'lg') && !isScreenWidthUp(windowSize.width, 'xl') && perex.length > PEREX_LENGTH_LG) {
		perexTruncated = `${perex.substring(0, PEREX_LENGTH_LG)}\u2026`;
	} else {
		perexTruncated = perex;
	}

	return (
		<article className={styles.wrap}>
			<Link
				className={styles.postLink}
				to={url}
			/>
			<div className={styles.imageWrap}>
				<Picture
					alt={previewImage.alt ?? title}
					className={styles.image}
					src={previewImage.src}
					src2x={previewImage.src2x}
					src2xWebP={previewImage.src2xWebP}
					srcBreakpoint={previewImage.srcBreakpoint}
					srcBreakpoint2x={previewImage.srcBreakpoint2x}
					srcBreakpoint2xWebP={previewImage.srcBreakpoint2xWebP}
					srcBreakpointWebP={previewImage.srcBreakpointWebP}
					srcWebP={previewImage.srcWebP}
					lazyLoad
				/>
				<div className={styles.badgeWrap}>
					<Badge label={topic} size="small" />
				</div>
			</div>
			<div className={styles.content}>
				<time className={styles.date} dateTime={date}>
					{updated && (
						<>
							{t('blogPost.updated')}
							{' '}
						</>
					)}
					{new Date(date).toLocaleString(language, { dateStyle: 'medium' })}
				</time>
				<h3 className={styles.title}>
					{title}
				</h3>
				<p className={styles.text}>
					{perexTruncated}
				</p>
				<Link
					className={styles.link}
					to={url}
				>
					{t('blogPost.preview.link')}
				</Link>
			</div>
		</article>
	);
}

BlogPostPreview.propTypes = {
	date: PropTypes.string,
	perex: PropTypes.string,
	previewImage: PropTypes.shape({
		alt: PropTypes.string,
		src: PropTypes.string.isRequired,
		src2x: PropTypes.string,
		src2xWebP: PropTypes.string,
		srcBreakpoint: PropTypes.string,
		srcBreakpoint2x: PropTypes.string,
		srcBreakpoint2xWebP: PropTypes.string,
		srcBreakpointWebP: PropTypes.string,
		srcWebP: PropTypes.string,
	}).isRequired,
	title: PropTypes.string.isRequired,
	topic: PropTypes.string,
	updated: PropTypes.bool,
	url: PropTypes.string.isRequired,
};

BlogPostPreview.defaultProps = {
	date: '',
	perex: '',
	topic: '',
	updated: false,
};
