import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';

import {
	AmlQuestionnaire,
	DocumentCheck,
	InvestmentQuestionnaire,
	PersonalInformationForm,
	PersonalInformationModal,
	QuestionnaireAccountClosure,
	QuestionnaireClosedAccount,
} from '../../components/common';
import { Col, Row, Section } from '../../components/layout';
import { EmailLink, Loader } from '../../components/ui';
import { useUserContext, useUserReload } from '../../contexts/UserContext';
import {
	clientCheckOverwriteEsgPreferenceAnswers,
	clientCheckResetEsgPreferenceQuestionnaire,
	fetchClientCheck,
	fetchChildrenList,
} from '../../utils/api';
import convertUser from '../../utils/convertUser';
import { toNumber } from '../../utils/number';
import useFetchWithReload, { useFetchWithHardReload } from '../../utils/useFetchWithReload';
import useRequestAuth from '../../utils/useRequestAuth';
import AccountSelect from './AccountSelect';
import Check from './Check';

import styles from './ClientCheck.module.scss';

const useFetchChildrenList = useFetchWithReload(fetchChildrenList);
const useFetchClientCheck = useFetchWithHardReload(fetchClientCheck);

export default function ClientCheck({
	accountUrl,
	blogPostEsgRegulationUrl,
	faqUrl,
}) {
	const [t] = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const childIdParam = searchParams.get('childId') ?? null;
	const childId = childIdParam !== null ? toNumber(childIdParam) || null : null;

	const [[accountClosureVisible, accountClosureClosedAccount], setAccountClosure] = useState([false, false]);
	const [showNameModal, setShowNameModal] = useState(false);
	const [user] = useUserContext();
	const reloadUser = useUserReload();
	const [childrenList, , , , reloadChildrenList] = useFetchChildrenList(null, !!user?.child);
	const [clientData, , , , reloadClientCheck] = useFetchClientCheck(null, childId);
	const overwriteEsgPreferenceAnswersAuth = useRequestAuth(clientCheckOverwriteEsgPreferenceAnswers);
	const resetEsgPreferenceQuestionnaireAuth = useRequestAuth(clientCheckResetEsgPreferenceQuestionnaire);

	const clientCheckUser = childIdParam !== null
		? childrenList?.find(({ id }) => id === childId) ?? null
		: convertUser(user);
	const invalidChildId = childIdParam !== null && childrenList !== null && clientCheckUser === null;

	useEffect(() => {
		if (invalidChildId) {
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.delete('childId');
			setSearchParams(newSearchParams);
		}
	}, [invalidChildId]);

	if (user?.child) {
		return user.client_zone_locked ? (
			<Section>
				<div className={styles.root}>
					<Row>
						<Col>
							<div className={styles.description}>
								<p className={styles.text}>
									{t('clientCheck.childDescription')}
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</Section>
		) : (
			<Navigate to={accountUrl} />
		);
	}

	if (user === null || childrenList === null || clientData === null || clientCheckUser === null) {
		return <Loader fullPage />;
	}

	const handleSelectChild = (newChildId) => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('childId', newChildId);
		setSearchParams(newSearchParams);

		setAccountClosure([false, false]);
		setShowNameModal(false);
	};

	const handleReload = () => {
		reloadUser();
		reloadChildrenList();
		reloadClientCheck();
	};

	const handleCloseAccount = () => {
		setAccountClosure(([, closedAccount]) => [true, closedAccount]);
	};

	const handleAccountClosureClose = () => {
		setAccountClosure(([, closedAccount]) => [false, closedAccount]);
	};

	const handleAccountClosureSuccess = () => {
		setAccountClosure([false, true]);
		handleReload();
	};

	return (
		<>
			{childrenList.length > 0 && (
				<AccountSelect
					childId={childId}
					childrenList={childrenList}
					selectChild={handleSelectChild}
				/>
			)}
			<Section>
				<div className={styles.root}>
					{childrenList.length > 0 && (
						<Row>
							<Col>
								<div className={styles.description}>
									<Trans i18nKey="clientCheck.description">
										<p className={styles.text}>
											<EmailLink />
										</p>
									</Trans>
								</div>
							</Col>
						</Row>
					)}
					<Row>
						<Col>
							<Check
								active={clientData.amlCheckDue}
								title="clientCheck.questionnaire.aml.title"
								lastCheckAt={clientData.lastAmlCheckAt}
								nextCheckAt={clientData.nextAmlCheckDueAt}
							>
								<AmlQuestionnaire
									blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
									childId={childId}
									closeAccount={handleCloseAccount}
									disabled={
										accountClosureVisible
										|| accountClosureClosedAccount
										|| clientCheckUser.accountClosure
									}
									faqUrl={faqUrl}
									investmentQuestionnaireResetAvailable={
										clientData.investmentQuestionnaireResetAvailable
									}
									overwriteEsgPreferenceAnswers={overwriteEsgPreferenceAnswersAuth}
									reloadClientCheck={handleReload}
									resetEsgPreferenceQuestionnaire={resetEsgPreferenceQuestionnaireAuth}
								/>
							</Check>
						</Col>
						<Col>
							<Check
								active={clientData.documentExpiryCheckDue}
								title="clientCheck.documents.title"
								lastCheckAt={clientData.lastDocumentExpiryCheckAt}
								nextCheckAt={clientData.nextDocumentExpiryCheckDueAt}
							>
								<DocumentCheck
									childId={childId}
									reloadClientCheck={handleReload}
								/>
							</Check>
						</Col>
						<Col>
							<Check
								active={
									clientData.personalInformationCheckDue
									|| clientCheckUser.phoneNumberPlanned
									|| clientCheckUser.phonePrefixPlanned
								}
								title="clientCheck.personalInformation.title"
								lastCheckAt={clientData.lastPersonalInformationCheckAt}
								nextCheckAt={clientData.nextPersonalInformationCheckDueAt}
							>
								<PersonalInformationForm
									childId={childId}
									reloadUser={handleReload}
									setShowNameModal={setShowNameModal}
									user={clientCheckUser}
								/>
							</Check>
						</Col>
						<Col>
							<Check
								active={clientData.questionnaireCheckDue}
								title="clientCheck.questionnaire.investment.title"
								lastCheckAt={clientData.lastQuestionnaireCheckAt}
								nextCheckAt={clientData.nextQuestionnaireCheckDueAt}
							>
								<InvestmentQuestionnaire
									blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
									childId={childId}
									closeAccount={handleCloseAccount}
									disabled={
										accountClosureVisible
										|| accountClosureClosedAccount
										|| clientCheckUser.accountClosure
									}
									faqUrl={faqUrl}
									investmentQuestionnaireResetAvailable={
										clientData.investmentQuestionnaireResetAvailable
									}
									overwriteEsgPreferenceAnswers={overwriteEsgPreferenceAnswersAuth}
									reloadClientCheck={handleReload}
									resetEsgPreferenceQuestionnaire={resetEsgPreferenceQuestionnaireAuth}
								/>
							</Check>
						</Col>
					</Row>
				</div>
				<PersonalInformationModal
					childId={childId}
					reloadUser={handleReload}
					showNameModal={showNameModal}
					setShowNameModal={setShowNameModal}
				/>
				<QuestionnaireAccountClosure
					childId={childId}
					isVisible={
						accountClosureVisible
						&& !accountClosureClosedAccount
						&& !clientCheckUser.accountClosure
					}
					onClose={handleAccountClosureClose}
					onSuccess={handleAccountClosureSuccess}
					userCurrency={user.currency_code}
				/>
				<QuestionnaireClosedAccount
					accountUrl={accountUrl}
					isVisible={clientCheckUser.accountClosure}
				/>
			</Section>
		</>
	);
}

ClientCheck.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	blogPostEsgRegulationUrl: PropTypes.string,
	faqUrl: PropTypes.string.isRequired,
};

ClientCheck.defaultProps = {
	blogPostEsgRegulationUrl: null,
};
