/* global document */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
	OnboardingAgreement,
	OnboardingPayment,
	OnboardingProfile,
	OnboardingQuestionnaire,
	ProgressIcon,
} from '../../../components/common';
import { Button, Toggle } from '../../../components/ui';
import { useCountry } from '../../../contexts/LocaleContext';
import { saveProfileChildOnlyClientProduct } from '../../../utils/api';
import useRequestAuth from '../../../utils/useRequestAuth';

import styles from './OnboardingSteps.module.scss';

const steps = [
	{
		id: 1,
		title: 'onboarding.steps.profileType.title',
	},
	{
		id: 2,
		title: 'onboarding.steps.questionnaire.title',
	},
	{
		id: 5,
		title: 'onboarding.steps.agreement.title',
	},
	{
		id: 6,
		title: 'onboarding.steps.payment.title',
	},
];

const questionnaires = [
	'AML',
	'ESG',
];

export default function OnboardingSteps({
	blogPostEsgRegulationUrl,
	category,
	dashboardUrl,
	faqUrl,
	process,
	setCheckOnboarding,
}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const country = useCountry();
	const [esg, setEsg] = useState(false);
	const saveProfileChildOnlyClientProductAuth = useRequestAuth(saveProfileChildOnlyClientProduct);

	useEffect(() => {
		if (process.step || questionnaires.includes(process.questionnaire)) {
			setTimeout(() => {
				const elementId = `step${process.step}`;
				document.getElementById(elementId)?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 200);
		}
	}, [process.step]);

	const handleProduct = async (e) => {
		e.preventDefault();
		try {
			await saveProfileChildOnlyClientProductAuth(esg, category);
		} finally {
			setCheckOnboarding(true);
		}
	};

	const handleCloseAccount = async () => {
		navigate(dashboardUrl);
	};

	return (
		<ul className={styles.root}>
			{steps.map((item, index) => (
				<li key={item.id} id={`step${item.id}`} className={`${styles.step} ${item.id === process.step ? styles.isStepActive : ''}`.trim()}>
					<div className={styles.stepHeader}>
						<div className={styles.stepNumber}>
							{`0${index + 1}`}
							{item.id <= process.step && (
								<span className={styles.stepIcon}>
									<ProgressIcon completed={item.id < process.step} />
								</span>
							)}
						</div>
						<div className={styles.stepInfo}>
							<h2 className={styles.stepTitle}>{t(item.title)}</h2>
						</div>
					</div>

					{item.id === 1 && item.id === process.step && (
						<div className={styles.stepContent}>
							<div className={styles.toggleWrap}>
								<div className={styles.toggleLabel}>
									{t('investorProfilePreview.toggleLabel')}
								</div>
								<Toggle
									labelFirst={t('investorProfilePreview.classicPortfolios')}
									labelSecond={t('investorProfilePreview.esgPortfolios')}
									setToggle={setEsg}
									toggle={esg}
									tooltipFirst={t('investorProfileChoice.tooltip.classic')}
									tooltipSecond={t('investorProfileChoice.tooltip.esg')}
								/>
							</div>
							{esg ? (
								<p>
									{t('investorProfileChoice.category.esg')}
								</p>
							) : (
								<p>
									{t('investorProfileChoice.category.classic')}
								</p>
							)}
							<Button
								label={t('portfolio.new.modal.add')}
								onClick={handleProduct}
							/>
						</div>
					)}

					{item.id === 2 && (
						<>
							<div className={styles.stepContent}>
								{item.id === process.step && (
									<p>
										{t('onboarding.steps.questionnaire.active')}
									</p>
								)}
								{item.id < process.step && (
									<>
										<p>
											{t('onboarding.steps.questionnaire.done')}
										</p>
										{process.investorProfileId && (
											<OnboardingProfile selectedProfileId={process.investorProfileId} />
										)}
									</>
								)}
							</div>
							{item.id === process.step && (
								<OnboardingQuestionnaire
									blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
									category={category}
									closeAccount={handleCloseAccount}
									faqUrl={faqUrl}
									investmentQuestionnaireResetAvailable={
										process.investmentQuestionnaireResetAvailable ?? false
									}
									isStarted={questionnaires.includes(process.questionnaire)}
									processQuestionnaires={process.questionnaires ?? []}
									product={process.product ?? 'classic'}
									setCheckOnboarding={setCheckOnboarding}
									type={process.questionnaire ?? 'investment'}
								/>
							)}
						</>
					)}

					{item.id === 5 && item.id === process.step && (
						<>
							<div className={styles.stepContent}>
								<p>
									{t('onboarding.steps.agreement.active')}
								</p>
								<p>
									{t('onboarding.steps.agreement.timeOutText')}
								</p>
							</div>
							<div className={styles.stepContent}>
								<OnboardingAgreement
									category={process.category}
									setCheckOnboarding={setCheckOnboarding}
								/>
							</div>
						</>
					)}

					{item.id === 6 && item.id === process.step && (
						<>
							<div className={styles.stepContent}>
								<p>
									{t('onboarding.steps.payment.active')}
								</p>
								<Trans i18nKey="onboarding.steps.payment.info">
									<span className={styles.listTitle} />
									<ul className={styles.list}>
										<li className={styles.listItem} />
									</ul>
								</Trans>
							</div>
							<div className={styles.stepContent}>
								<OnboardingPayment
									isPension={country === 'CZ' && process.category === 'pension'}
									portfolioId={process.portfolioId ?? null}
									setCheckOnboarding={setCheckOnboarding}
								/>
							</div>
						</>
					)}
				</li>
			))}
		</ul>
	);
}

OnboardingSteps.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	category: PropTypes.string,
	dashboardUrl: PropTypes.string.isRequired,
	faqUrl: PropTypes.string.isRequired,
	process: PropTypes.shape({
		category: PropTypes.string,
		investmentQuestionnaireResetAvailable: PropTypes.bool,
		investorProfileId: PropTypes.number,
		portfolioId: PropTypes.number,
		product: PropTypes.string,
		questionnaire: PropTypes.string,
		questionnaires: PropTypes.arrayOf(PropTypes.shape({
			questionnaire: PropTypes.string.isRequired,
			finished: PropTypes.bool.isRequired,
		}).isRequired),
		step: PropTypes.number,
	}).isRequired,
	setCheckOnboarding: PropTypes.func.isRequired,
};

OnboardingSteps.defaultProps = {
	blogPostEsgRegulationUrl: null,
	category: null,
};
