import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BlogHeader } from '../../../components/common';
import { Container, Section } from '../../../components/layout';
import { Button } from '../../../components/ui';
import BlogPostPreview from './BlogPostPreview';

import styles from './BlogList.module.scss';

const POSTS_PER_PAGE = 3;

export default function BlogList({
	blogPostUrl,
	posts,
}) {
	const [t] = useTranslation();
	const [page, setPage] = useState(1);

	const postsSliced = posts.slice(0, POSTS_PER_PAGE * page);

	return (
		<Section>
			<BlogHeader />
			<Container>
				{postsSliced.map((post) => (
					<BlogPostPreview
						key={post.id}
						date={post.date}
						perex={post.perex}
						previewImage={post.previewImage}
						title={post.title}
						topic={post.topic}
						updated={post.updated}
						url={blogPostUrl(post)}
					/>
				))}
				{postsSliced.length < posts.length && (
					<div className={styles.buttonWrap}>
						<Button
							onClick={() => setPage(page + 1)}
							label={t('blogPost.list.button')}
							outline
						/>
					</div>
				)}
				{posts.length <= 1 && (
					<p className={styles.description}>{t('blog.comingSoon')}</p>
				)}
			</Container>
		</Section>
	);
}

BlogList.propTypes = {
	blogPostUrl: PropTypes.func.isRequired,
	posts: PropTypes.arrayOf(PropTypes.shape({
		date: PropTypes.string,
		id: PropTypes.string.isRequired,
		perex: PropTypes.string,
		previewImage: PropTypes.shape({
			alt: PropTypes.string,
			src: PropTypes.string.isRequired,
			src2x: PropTypes.string,
			src2xWebP: PropTypes.string,
			srcBreakpoint: PropTypes.string,
			srcBreakpoint2x: PropTypes.string,
			srcBreakpoint2xWebP: PropTypes.string,
			srcBreakpointWebP: PropTypes.string,
			srcWebP: PropTypes.string,
		}).isRequired,
		title: PropTypes.string.isRequired,
		topic: PropTypes.string,
		updated: PropTypes.bool,
	}).isRequired).isRequired,
};
