export default {
	account: 'moj-ucet',
	blog: 'blog',
	childProduct: 'produkty/ucet-pre-dite',
	classicProduct: 'produkty/klasicky-ucet',
	clientCheck: 'kontrola',
	contact: 'opytajte-sa',
	cookies: 'cookies',
	dashboard: 'prehlad',
	documents: 'dokumenty',
	emailAlias: 'prepojenie-uctov',
	emailVerification: 'registracia/overenie-emailu',
	esgProduct: 'produkty/esg-ucet',
	faq: 'caste-otazky',
	fees: 'poplatky',
	forgotPassword: 'prihlasenie/obnova-hesla',
	history: 'historia',
	homepage: '',
	howItWorks: 'ako-to-funguje',
	login: 'prihlasenie',
	offers: 'nieco-navyse',
	onboarding: 'dokoncenie-registracie',
	onboardingChild: 'registracia-dieta',
	onboardingParent: 'registracia-rodica',
	ourApproach: 'nas-pristup',
	pensionProduct: 'produkty/penzijny-investicny-ucet',
	portfolio: 'portfolio',
	privacyPolicy: 'ochrana-osobnych-udajov',
	referrals: 'odporucanie',
	registration: 'registracia',
	resetPassword: 'prihlasenie/zmena-hesla',
	signAgreement: 'podpis-zmluvy',
	signNewAgreement: 'podpis-novej-zmluvy',
	taxes: 'dane2023',
	unsubscribe: 'unsubscribe',
};

export const subpages = {
	blog: {
		esg: 'co-je-esg-investovanie-a-preco-investovat-udrzatelne',
		esgComparison: 'kompletne-porovnanie-klasickych-a-esg-investicnych-portfolii-fondee#a-co-zhodnotenie-porovnali-sme-vyvoj-oboch-typov-portfolii',
		etf: 'co-su-to-etf-preco-by-vas-mali-zaujimat-a-co-o-nich-musite-vediet',
		taxes: 'ako-sa-zdanuju-investicie-do-etf',
	},
};
