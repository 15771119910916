import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ChildPersonalInformationForm, ChildManualQuestionnaireTriggerModal } from '../../../components/common';
import { fetchClientCheck } from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import ChildClosure from '../ChildClosure';

import styles from './ChildPersonalInformation.module.scss';

const useFetchClientCheck = useFetchAuth(fetchClientCheck);

export default function ChildPersonalInformation({
	child,
	clientCheckUrl,
	currencies,
	portfolioUrl,
	reloadChildrenList,
	reloadUser,
	userCurrency,
}) {
	const [clientData] = useFetchClientCheck(null, child.id);
	const [t] = useTranslation();

	return (
		<div className={styles.wrap}>
			<div className={styles.title}>
				{t('account.childList.personalInformation.title')}
			</div>
			<ChildPersonalInformationForm
				child={child}
				reloadChildrenList={reloadChildrenList}
			/>
			<ChildClosure
				childId={child.id}
				currencies={currencies}
				inline
				portfolioUrl={portfolioUrl}
				reloadChildrenList={reloadChildrenList}
				reloadUser={reloadUser}
				userCurrency={userCurrency}
			/>
			<ChildManualQuestionnaireTriggerModal
				childId={child.id}
				clientCheckUrl={clientCheckUrl}
				lastCheck={clientData?.lastQuestionnaireCheckAt}
				nextCheck={clientData?.nextManualQuestionnaireCheckAt}
			/>
		</div>
	);
}

ChildPersonalInformation.propTypes = {
	child: PropTypes.shape({
		email: PropTypes.string,
		id: PropTypes.number.isRequired,
		phoneNumber: PropTypes.string,
		phonePrefix: PropTypes.string,
	}).isRequired,
	clientCheckUrl: PropTypes.string.isRequired,
	currencies: PropTypes.arrayOf(PropTypes.shape({
		code: PropTypes.string.isRequired,
		codeShort: PropTypes.string.isRequired,
		codeShortBefore: PropTypes.bool.isRequired,
	}).isRequired),
	portfolioUrl: PropTypes.string.isRequired,
	reloadChildrenList: PropTypes.func.isRequired,
	reloadUser: PropTypes.func.isRequired,
	userCurrency: PropTypes.string,
};

ChildPersonalInformation.defaultProps = {
	currencies: null,
	userCurrency: null,
};
