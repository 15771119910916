import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useLanguage } from '../../../contexts/LocaleContext';
import {
	fetchOnboardingQuestionnaire,
	onboardingOverwriteEsgPreferenceAnswers,
	onboardingResetEsgPreferenceQuestionnaire,
	saveQuestionnaireAnswer,
} from '../../../utils/api';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import Questionnaire from '../Questionnaire';

const useFetchQuestionnaire = useFetchAuth(fetchOnboardingQuestionnaire);

const initialData = {
	questionnaire: [],
	lastAnsweredQuestionId: null,
	skippedQuestionIds: [],
	usPerson: false,
};

export default function OnboardingQuestionnaire({
	blogPostEsgRegulationUrl,
	category,
	childId,
	closeAccount,
	disabled,
	faqUrl,
	investmentQuestionnaireResetAvailable,
	isStarted,
	processQuestionnaires,
	product,
	setCheckOnboarding,
	type,
}) {
	const language = useLanguage();
	const [questionnaireData, loading, error] = useFetchQuestionnaire(initialData, language, childId);
	const overwriteEsgPreferenceAnswersAuth = useRequestAuth(onboardingOverwriteEsgPreferenceAnswers);
	const resetEsgPreferenceQuestionnaireAuth = useRequestAuth(onboardingResetEsgPreferenceQuestionnaire);
	const saveQuestionnaireAnswerAuth = useRequestAuth(saveQuestionnaireAnswer);

	const reloadQuestionnaire = useCallback(() => {
		setCheckOnboarding(true);
	}, [setCheckOnboarding]);

	if (loading || error) {
		return null;
	}

	return (
		<Questionnaire
			blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
			category={category}
			childId={childId}
			closeAccount={closeAccount}
			disabled={disabled}
			faqUrl={faqUrl}
			investmentQuestionnaireResetAvailable={investmentQuestionnaireResetAvailable}
			isStarted={isStarted}
			overwriteEsgPreferenceAnswers={overwriteEsgPreferenceAnswersAuth}
			processQuestionnaires={processQuestionnaires}
			product={product}
			questionnaireData={questionnaireData}
			reloadQuestionnaire={reloadQuestionnaire}
			resetEsgPreferenceQuestionnaire={resetEsgPreferenceQuestionnaireAuth}
			saveQuestionnaireAnswer={saveQuestionnaireAnswerAuth}
			type={type}
		/>
	);
}

OnboardingQuestionnaire.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	category: PropTypes.string,
	childId: PropTypes.number,
	closeAccount: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	faqUrl: PropTypes.string.isRequired,
	investmentQuestionnaireResetAvailable: PropTypes.bool.isRequired,
	isStarted: PropTypes.bool.isRequired,
	processQuestionnaires: PropTypes.arrayOf(PropTypes.shape({
		questionnaire: PropTypes.string.isRequired,
		finished: PropTypes.bool.isRequired,
	}).isRequired).isRequired,
	product: PropTypes.string.isRequired,
	setCheckOnboarding: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

OnboardingQuestionnaire.defaultProps = {
	blogPostEsgRegulationUrl: null,
	category: null,
	childId: null,
	disabled: false,
};
