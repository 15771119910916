import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useFocusOnError from '../../../utils/useFocusOnError';
import { Tooltip } from '../../ui';

import styles from './TextField.module.scss';

export default function TextField({
	autoComplete,
	autoFocus,
	center,
	disabled,
	error,
	helper,
	id,
	inputMode,
	isFlat,
	isLabelHidden,
	label,
	max,
	min,
	maxLength,
	minLength,
	name,
	noError,
	onBlur,
	onChange,
	onPaste,
	pattern,
	placeholder,
	readonly,
	required,
	step,
	tooltip,
	type,
	unit,
	value,
	warning,
}) {
	const [passwordShown, setPasswordShown] = useState(false);
	const inputRef = useRef();
	useFocusOnError(name, inputRef);

	const togglePasswordVisiblity = () => {
		setPasswordShown(!passwordShown);
	};

	useEffect(() => {
		if (autoFocus) {
			setTimeout(() => {
				inputRef.current?.focus();
			}, 50);
		}
	}, [autoFocus]);

	return (
		<div className={`${styles.root} ${!noError && error ? styles.rootError : ''} ${center ? styles.center : ''} ${noError ? styles.noError : ''} ${disabled ? styles.disabled : ''}`.trim()}>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''}`.trim()}>
				<label className={styles.label} htmlFor={id}>{label}</label>
				{tooltip && (
					<Tooltip
						text={tooltip}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
							width={15}
							className={styles.tooltip}
						>
							<path
								fill="currentColor"
								d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
							/>
						</svg>
					</Tooltip>
				)}
				{helper && <div className={styles.helper}>{helper}</div>}
			</div>
			<div className={styles.inputWrap}>
				<input
					ref={inputRef}
					autoComplete={autoComplete}
					className={`${styles.input} ${isFlat ? styles.inputFlat : ''} ${unit ? styles.inputWithUnit : ''}`.trim()}
					disabled={disabled}
					id={id}
					inputMode={inputMode}
					min={min}
					max={max}
					maxLength={maxLength}
					minLength={minLength}
					name={name}
					onBlur={onBlur}
					onChange={onChange}
					onPaste={onPaste}
					pattern={pattern}
					placeholder={placeholder}
					readOnly={readonly}
					required={required}
					step={step}
					type={passwordShown ? 'text' : type}
					value={value}
				/>
				{unit && (
					<span className={styles.unit}>
						{unit}
					</span>
				)}
				{type === 'password' && (
					<button
						type="button"
						onClick={togglePasswordVisiblity}
						className={styles.passwordToggle}
						tabIndex="-1"
					>
						{passwordShown ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 640 512"
								width="25"
								height="20"
							>
								<path
									fill="currentColor"
									d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 000 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 01-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0081.25-102.07 32.35 32.35 0 000-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 00-147.32 37.7L45.46 3.37A16 16 0 0023 6.18L3.37 31.45A16 16 0 006.18 53.9l588.36 454.73a16 16 0 0022.46-2.81l19.64-25.27a16 16 0 00-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 00416 256a94.76 94.76 0 00-121.31-92.21A47.65 47.65 0 01304 192a46.64 46.64 0 01-1.54 10l-73.61-56.89A142.31 142.31 0 01320 112a143.92 143.92 0 01144 144c0 21.63-5.29 41.79-13.9 60.11z"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 576 512"
								width="25"
								height="20"
							>
								<path
									fill="currentColor"
									d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 000 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 000-29.19zM288 400a144 144 0 11144-144 143.93 143.93 0 01-144 144zm0-240a95.31 95.31 0 00-25.31 3.79 47.85 47.85 0 01-66.9 66.9A95.78 95.78 0 10288 160z"
								/>
							</svg>
						)}
					</button>
				)}
			</div>
			{!noError && error && (
				<div className={styles.error}>{error}</div>
			)}
			{!noError && warning && (
				<div className={styles.warning}>{warning}</div>
			)}
		</div>
	);
}

TextField.propTypes = {
	autoComplete: PropTypes.string,
	autoFocus: PropTypes.bool,
	center: PropTypes.bool,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	helper: PropTypes.string,
	id: PropTypes.string.isRequired,
	inputMode: PropTypes.string,
	isFlat: PropTypes.bool,
	isLabelHidden: PropTypes.bool,
	label: PropTypes.string.isRequired,
	max: PropTypes.number,
	min: PropTypes.number,
	maxLength: PropTypes.number,
	minLength: PropTypes.number,
	name: PropTypes.string.isRequired,
	noError: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onPaste: PropTypes.func,
	pattern: PropTypes.string,
	placeholder: PropTypes.string,
	readonly: PropTypes.bool,
	required: PropTypes.bool,
	step: PropTypes.number,
	tooltip: PropTypes.string,
	type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'search', 'tel']),
	unit: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	warning: PropTypes.string,
};

TextField.defaultProps = {
	autoComplete: null,
	autoFocus: false,
	center: false,
	disabled: false,
	error: '',
	helper: '',
	inputMode: null,
	isFlat: false,
	isLabelHidden: false,
	min: null,
	max: null,
	maxLength: null,
	minLength: null,
	noError: false,
	onBlur: null,
	onChange: null,
	onPaste: null,
	pattern: null,
	placeholder: null,
	readonly: false,
	required: false,
	step: null,
	tooltip: null,
	type: 'text',
	unit: null,
	value: undefined,
	warning: '',
};
