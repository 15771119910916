import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { useCountry, useLanguage } from '../../../../contexts/LocaleContext';
import {
	clientCheckSavePortfolioInvestorProfile,
	fetchInvestorProfile,
	fetchShowEsgWarning,
} from '../../../../utils/api';
import { useTrackGTMEvent } from '../../../../utils/GTMProvider';
import useFetch from '../../../../utils/useFetch';
import useFetchAuth from '../../../../utils/useFetchAuth';
import useRequestAuth from '../../../../utils/useRequestAuth';
import {
	Alert,
	Button,
	Modal,
	Toggle,
} from '../../../ui';
import InvestorProfileDetailSelection from '../../InvestorProfileDetail/InvestorProfileDetailSelection';
import InvestorProfileDetailSlider from '../../InvestorProfileDetail/InvestorProfileDetailSlider';
import PortfolioRanger from '../PortfolioRanger/PortfolioRanger';

import styles from './PortfolioSetting.module.scss';

const useFetchInvestorProfile = useFetch(fetchInvestorProfile);
const useFetchShowEsgWarning = useFetchAuth(fetchShowEsgWarning);

const initialEsg = [null, true];

const updateEsgToggle = (nextEsgToggle) => (esg) => {
	const [esgToggle] = esg;
	return nextEsgToggle !== esgToggle ? [nextEsgToggle, nextEsgToggle === null] : esg;
};

const updateEsgWarningDismissed = (esg) => {
	const [esgToggle, esgWarningDismissed] = esg;
	return !esgWarningDismissed ? [esgToggle, true] : esg;
};

const getProfileIndex = (profiles, profileId, defaultIndex = null) => {
	if (!profiles) {
		return defaultIndex;
	}

	for (let i = 0; i < profiles.length; i += 1) {
		if (profiles[i].id === profileId) {
			return i;
		}
	}

	return defaultIndex;
};

const noop = () => {};

export default function PortfolioSetting({
	childId,
	closeAccount,
	disabled,
	investmentQuestionnaireResetAvailable,
	investorProfileData,
	portfolio,
	portfolioSettingsAnchor,
	reloadPortfolios,
	resetQuestionnaire,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();
	const trackGTMEvent = useTrackGTMEvent();

	const [modalOpen, setModalOpen] = useState(false);
	const [[esgToggle, esgWarningDismissed], setEsg] = useState(initialEsg);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [notRecommendedModalOpen, setNotRecommendedModalOpen] = useState(false);
	const [error, setError] = useState(false);

	const [profiles, loading] = useFetchInvestorProfile({}, country, language);
	const [showEsgWarning] = useFetchShowEsgWarning(false, childId);
	const clientCheckSavePortfolioInvestorAuth = useRequestAuth(clientCheckSavePortfolioInvestorProfile);

	const plannedProfile = portfolio.investorProfileNextId ?? portfolio.investorProfilePlannedId;
	const esgPlanned = !!profiles.investorProfilesEsg?.filter(({ id }) => id === plannedProfile)[0];

	const setEsgToggle = useCallback((nextEsgToggle) => {
		const nextRecommendedProfiles = nextEsgToggle
			? investorProfileData.recommendedProfilesEsg
			: investorProfileData.recommendedProfiles;
		const nextDefaultProfile = nextEsgToggle
			? investorProfileData.defaultProfileEsg
			: investorProfileData.defaultProfile;

		if (nextEsgToggle !== esgPlanned && (
			!nextRecommendedProfiles
			|| nextRecommendedProfiles.length === 0
			|| !nextDefaultProfile
		)) {
			return;
		}

		setSelectedProfile(null);
		setEsg(updateEsgToggle(nextEsgToggle));
	}, [investorProfileData, esgPlanned]);
	const setEsgWarningDismissed = useCallback(() => setEsg(updateEsgWarningDismissed), []);

	const esgAvailable = esgPlanned || (
		!!investorProfileData.recommendedProfilesEsg
		&& investorProfileData.recommendedProfilesEsg.length > 0
		&& !!investorProfileData.defaultProfileEsg
	);
	const esgActive = esgAvailable && (esgToggle ?? esgPlanned);

	const investorProfiles = esgActive ? profiles.investorProfilesEsg : profiles.investorProfiles;
	const defaultProfile = (esgActive === esgPlanned ? plannedProfile : null)
		?? (esgActive ? investorProfileData.defaultProfileEsg : investorProfileData.defaultProfile);
	const recommendedProfiles = esgActive
		? investorProfileData.recommendedProfilesEsg
		: investorProfileData.recommendedProfiles;
	const currentProfile = selectedProfile ?? defaultProfile ?? null;
	const currentSlide = getProfileIndex(investorProfiles, currentProfile, 3);

	const setCurrentSlide = useCallback((profileId) => {
		const { id = null } = investorProfiles?.[profileId] ?? {};
		if (id === null || id === selectedProfile) {
			return;
		}

		setSelectedProfile(id);
		if (id !== plannedProfile && (recommendedProfiles === null || recommendedProfiles?.includes(id) === false)) {
			setNotRecommendedModalOpen(true);
		}
	}, [selectedProfile, plannedProfile, investorProfiles, recommendedProfiles]);

	if (loading) {
		return null;
	}

	const handleClose = (e) => {
		e?.preventDefault();
		setModalOpen(false);
		setEsg(initialEsg);
		setSelectedProfile(null);
		setNotRecommendedModalOpen(false);
		setError(false);
	};

	const handleSubmitForm = async () => {
		setError(false);
		try {
			await clientCheckSavePortfolioInvestorAuth(portfolio.id, currentProfile);
		} catch {
			setError(true);
			return;
		}
		trackGTMEvent('interactions', {
			eventCategory: 'interactions',
			eventAction: 'buttonClick',
			eventLabel: 'zmenit_investicni_profil',
		});
		handleClose();
		reloadPortfolios();
	};

	return (
		<div className={styles.root} id={portfolioSettingsAnchor}>
			<div className={styles.inputWrap}>
				<div className={styles.portfolioName}>
					<div className={styles.imageWrap}>
						<img
							className={styles.image}
							src={`${process.env.PUBLIC_URL}/images/faces/${portfolio.faceImageFile}`}
							alt={portfolio.name}
							height={42}
						/>
					</div>
					<div className={styles.portfolioNameText} title={portfolio.name}>
						{portfolio.name}
					</div>
				</div>
				<PortfolioRanger
					currentProfile={portfolio.investorProfileId}
					esgPlanned={esgPlanned}
					plannedProfile={plannedProfile}
					investorProfiles={profiles.investorProfiles ?? []}
					investorProfilesEsg={profiles.investorProfilesEsg ?? []}
					recommendedProfiles={investorProfileData.recommendedProfiles ?? null}
					recommendedProfilesEsg={investorProfileData.recommendedProfilesEsg ?? null}
				/>
				<div className={styles.customButton}>
					<Button
						disabled={disabled}
						isFlat
						label={t('account.investorProfileControl.button')}
						onClick={() => setModalOpen(true)}
						outline
					/>
				</div>
				<Modal
					isVisible={!disabled && modalOpen}
					onClose={() => handleClose()}
					size="large"
					title={t('account.investorProfileControl.modal.title')}
				>
					<Formik
						initialValues={{}}
						onSubmit={handleSubmitForm}
					>
						{({
							handleSubmit,
							isSubmitting,
						}) => (
							<>
								<form onSubmit={handleSubmit}>
									{error && (
										<Alert type="danger">
											{t('forms.error')}
										</Alert>
									)}
									{esgAvailable && (
										<div className={styles.toggleWrap}>
											<Toggle
												inputNameDefault={`toggle-portfolio-settings-${portfolio.id}-classic`}
												inputNameSecondary={`toggle-portfolio-settings-${portfolio.id}-esg`}
												labelFirst={t('investorProfilePreview.classicPortfolios')}
												labelSecond={t('investorProfilePreview.esgPortfolios')}
												setToggle={setEsgToggle}
												toggle={esgActive}
												tooltipFirst={t('investorProfileChoice.tooltip.classic')}
												tooltipSecond={t('investorProfileChoice.tooltip.esg')}
											/>
										</div>
									)}
									<InvestorProfileDetailSlider
										currentSlide={currentSlide}
										products={profiles.products ?? []}
										profiles={investorProfiles ?? []}
										setCurrentSlide={modalOpen ? setCurrentSlide : noop}
									/>
									<InvestorProfileDetailSelection
										currentSlide={currentSlide}
										onSelectionChange={modalOpen ? setCurrentSlide : noop}
										profiles={investorProfiles ?? []}
										profilesRecommend={recommendedProfiles ?? null}
									/>
									<div className={styles.modalControl}>
										<Button
											disabled={disabled || isSubmitting}
											isSubmit
											label={t('account.investorProfileControl.modal.submit')}
										/>
										<div className={styles.modalClose}>
											<a
												href="#close"
												onClick={(e) => handleClose(e)}
											>
												{t('account.investorProfileControl.modal.cancelAllProfilesCheck')}
											</a>
										</div>
									</div>
								</form>
								<Modal
									isVisible={!disabled && showEsgWarning && esgActive && !esgWarningDismissed}
									onClose={setEsgWarningDismissed}
									title={t('general.esgWarningModal.title')}
								>
									<div className={styles.modal}>
										<p>{t('general.esgWarningModal.text')}</p>
										<div className={styles.modalFooter}>
											<Button
												disabled={disabled}
												label={t('general.esgWarningModal.button')}
												onClick={setEsgWarningDismissed}
											/>
										</div>
									</div>
								</Modal>
								<Modal
									isVisible={!disabled && notRecommendedModalOpen}
									onClose={() => {
										setSelectedProfile(null);
										setNotRecommendedModalOpen(false);
									}}
									title={t('investorProfileChoice.notRecommended.title')}
								>
									<div className={styles.modal}>
										<Trans i18nKey="investorProfileChoice.notRecommended.clientMessage">
											<p className={styles.modalText} />
										</Trans>
										<Button
											disabled={disabled}
											label={t('investorProfileChoice.notRecommended.clientClose')}
											onClick={() => {
												setSelectedProfile(null);
												setNotRecommendedModalOpen(false);
											}}
										/>
										<div className={styles.modalButtons}>
											<Button
												disabled={disabled || !investmentQuestionnaireResetAvailable}
												label={t('investorProfileChoice.notRecommended.redo')}
												onClick={resetQuestionnaire}
												outline
												tooltip={!investmentQuestionnaireResetAvailable ? t('investorProfileChoice.notRecommended.tooltip') : null}
											/>
										</div>
										<div className={styles.modalLinks}>
											<Button
												disabled={disabled}
												isLink
												label={t('investorProfileChoice.notRecommended.submitAbort')}
												onClick={closeAccount}
											/>
										</div>
									</div>
								</Modal>
							</>
						)}
					</Formik>
				</Modal>
			</div>
		</div>
	);
}

PortfolioSetting.propTypes = {
	childId: PropTypes.number,
	closeAccount: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	investmentQuestionnaireResetAvailable: PropTypes.bool.isRequired,
	investorProfileData: PropTypes.shape({
		defaultProfile: PropTypes.number,
		defaultProfileEsg: PropTypes.number,
		recommendedProfiles: PropTypes.arrayOf(PropTypes.number.isRequired),
		recommendedProfilesEsg: PropTypes.arrayOf(PropTypes.number.isRequired),
	}).isRequired,
	portfolio: PropTypes.shape({
		child: PropTypes.shape({
			id: PropTypes.number.isRequired,
		}),
		faceImageFile: PropTypes.string.isRequired,
		id: PropTypes.number.isRequired,
		investorProfileClosedChange: PropTypes.bool.isRequired,
		investorProfileId: PropTypes.number.isRequired,
		investorProfileNextId: PropTypes.number,
		investorProfilePlannedId: PropTypes.number.isRequired,
		name: PropTypes.string,
	}).isRequired,
	portfolioSettingsAnchor: PropTypes.string.isRequired,
	reloadPortfolios: PropTypes.func.isRequired,
	resetQuestionnaire: PropTypes.func.isRequired,
};

PortfolioSetting.defaultProps = {
	childId: null,
	disabled: false,
};
