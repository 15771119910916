/* global window */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import useScrollingOnBodyDisable from '../../../utils/useScrollingOnBodyDisable';
import styles from './Modal.module.scss';

const onWrapperClick = (e) => {
	e.stopPropagation();
};

export default function Modal({
	isVisible,
	children,
	size,
	title,
	subtitle,
	onClose,
}) {
	const onOutsideClick = isVisible && onClose ? onClose : null;
	const onRootClick = useCallback(() => {
		if (onOutsideClick !== null) {
			onOutsideClick();
		}
	}, [onOutsideClick]);

	useEffect(() => {
		if (!isVisible || !onClose) {
			return undefined;
		}

		const handleKeyDown = (e) => {
			if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
				onClose();
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [isVisible, onClose]);

	useScrollingOnBodyDisable(isVisible);

	return (
		<div role="presentation" className={`${styles.backdrop} ${isVisible ? styles.isVisible : ''}`.trim()} onClick={onRootClick}>
			<div role="presentation" className={`${styles.root} ${styles[size]}`} onClick={onWrapperClick}>
				{onClose && (
					<button
						type="button"
						className={styles.close}
						aria-label="Close"
						onClick={onClose}
					>
						<span aria-hidden="true">×</span>
					</button>
				)}
				{title && (
					<div className={styles.header}>
						<h3 className={styles.title}>{title}</h3>
						{subtitle && <p className={styles.subtitle}>{subtitle}</p>}
					</div>
				)}
				<div className={styles.body}>
					{children}
				</div>
			</div>
		</div>
	);
}

Modal.propTypes = {
	isVisible: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	size: PropTypes.oneOf(['default', 'medium', 'large']),
	title: PropTypes.string,
	subtitle: PropTypes.string,
	onClose: PropTypes.func,
};

Modal.defaultProps = {
	isVisible: false,
	size: 'default',
	title: '',
	subtitle: '',
	onClose: null,
};
