import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import useFocusOnError from '../../../utils/useFocusOnError';

import styles from './SelectField.module.scss';

const Input = (props) => {
	// eslint-disable-next-line react/prop-types,react/destructuring-assignment
	const { autoComplete = props.autoComplete } = props.selectProps;
	// eslint-disable-next-line react/jsx-props-no-spreading
	return <components.Input {...props} autoComplete={autoComplete} />;
};

export default function SelectField({
	autoComplete,
	onBlur,
	onChange,
	error,
	disabled,
	id,
	isLabelHidden,
	isSmall,
	label,
	name,
	options,
	placeholder,
	required,
	searchable,
	value,
}) {
	const handleChange = useCallback((newValue) => {
		if (onChange) {
			onChange(name, newValue && newValue.value != null ? newValue.value : null); // == - null or undefined
		}
	}, [onChange, name]);

	const blurred = useRef(null);
	const elementRef = useRef();
	const selectRef = useRef();

	const handleBlur = useCallback((e) => {
		if (e.persist) {
			e.persist();
		}
		blurred.current = e;
	}, []);

	useFocusOnError(name, selectRef, elementRef);

	useEffect(() => {
		if (blurred.current) {
			if (onBlur !== null) {
				onBlur(name)(blurred.current);
			}
			blurred.current = null;
		}
	});

	const selectValue = useMemo(() => {
		if (value == null) { // == - null or undefined
			return value;
		}

		for (let i = 0; i < options.length; i += 1) {
			if (value === options[i].value) {
				return options[i];
			}
		}

		return null;
	}, [options, value]);

	return (
		<div ref={elementRef} className={`${styles.root} ${isSmall ? styles.small : ''}`.trim()}>
			<div className={`${styles.labelWrap} ${isLabelHidden ? styles.labelWrapHidden : ''}`.trim()}>
				<label className={styles.label} htmlFor={id}>{label}</label>
			</div>
			<div
				className={`${styles.selectFieldWrap} ${error && styles.selectFieldWrapError}`.trim()}
			>
				<Select
					ref={selectRef}
					autoComplete={autoComplete}
					components={{ Input }}
					blurInputOnSelect
					classNamePrefix="selectField"
					inputId={id}
					isDisabled={disabled}
					isOptionDisabled={(option) => option.disabled}
					isSearchable={searchable}
					name={name}
					onChange={handleChange}
					onBlur={handleBlur}
					openMenuOnFocus
					options={options}
					placeholder={placeholder}
					required={required}
					value={selectValue}
				/>
			</div>
			{error && (
				<div className={styles.error}>{error}</div>
			)}
		</div>
	);
}

SelectField.propTypes = {
	autoComplete: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	isLabelHidden: PropTypes.bool,
	isSmall: PropTypes.bool,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.shape({
		isDisabled: PropTypes.bool,
		label: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]).isRequired,
		value: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.number.isRequired,
		]).isRequired,
	}).isRequired).isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	searchable: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
};

SelectField.defaultProps = {
	autoComplete: null,
	onBlur: null,
	onChange: null,
	error: '',
	disabled: false,
	isLabelHidden: false,
	isSmall: false,
	placeholder: '',
	required: false,
	searchable: false,
	value: undefined,
};
