/* global document */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
	OnboardingAgreement,
	OnboardingProfile,
	OnboardingQuestionnaire,
	ProgressIcon,
	QuestionnaireAccountClosure,
	QuestionnaireClosedAccount,
} from '../../../components/common';
import { useUserContext, useUserReload } from '../../../contexts/UserContext';

import styles from './SignAgreementSteps.module.scss';

const steps = [
	{
		id: 2,
		title: 'onboarding.steps.questionnaire.title',
	},
	{
		id: 5,
		title: 'onboarding.steps.agreement.title',
	},
];

const questionnaires = [
	'AML',
	'ESG',
];

export default function SignAgreementSteps({
	accountUrl,
	blogPostEsgRegulationUrl,
	faqUrl,
	process,
	setCheckOnboarding,
}) {
	const [t] = useTranslation();
	const [[accountClosureVisible, accountClosureClosedAccount], setAccountClosure] = useState([false, false]);
	const [user] = useUserContext();
	const reloadUser = useUserReload();

	useEffect(() => {
		if (process.step || questionnaires.includes(process.questionnaire)) {
			setTimeout(() => {
				const elementId = `step${process.step}`;
				document.getElementById(elementId)?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 200);
		}
	}, [process.step]);

	if (user === null) {
		return null;
	}

	const handleCloseAccount = () => {
		setAccountClosure(([, closedAccount]) => [true, closedAccount]);
	};

	const handleAccountClosureClose = () => {
		setAccountClosure(([, closedAccount]) => [false, closedAccount]);
	};

	const handleAccountClosureSuccess = () => {
		setAccountClosure([false, true]);
		reloadUser();
	};

	return (
		<ul className={styles.root}>
			{steps.map((item, index) => (
				<li key={item.id} id={`step${item.id}`} className={`${styles.step} ${item.id === process.step ? styles.isStepActive : ''}`.trim()}>
					<div className={styles.stepHeader}>
						<div className={styles.stepNumber}>
							{`0${index + 1}`}
							{item.id <= process.step && (
								<span className={styles.stepIcon}>
									<ProgressIcon completed={item.id < process.step} />
								</span>
							)}
						</div>
						<div className={styles.stepInfo}>
							<h2 className={styles.stepTitle}>{t(item.title)}</h2>
						</div>
					</div>

					{item.id === 2 && (
						<>
							<div className={styles.stepContent}>
								{item.id === process.step && (
									<p>
										{t('onboarding.steps.questionnaire.signAgreementActive')}
									</p>
								)}
								{item.id < process.step && (
									<>
										<p>
											{t('onboarding.steps.questionnaire.done')}
										</p>
										{process.investorProfileId && (
											<OnboardingProfile selectedProfileId={process.investorProfileId} />
										)}
									</>
								)}
							</div>
							{item.id === process.step && (
								<OnboardingQuestionnaire
									blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
									closeAccount={handleCloseAccount}
									disabled={
										accountClosureVisible
										|| accountClosureClosedAccount
										|| user.account_closure
									}
									faqUrl={faqUrl}
									investmentQuestionnaireResetAvailable={
										process.investmentQuestionnaireResetAvailable ?? false
									}
									isStarted={questionnaires.includes(process.questionnaire)}
									processQuestionnaires={process.questionnaires ?? []}
									product="classic"
									setCheckOnboarding={setCheckOnboarding}
									type={process.questionnaire ?? 'investment'}
								/>
							)}
						</>
					)}

					{item.id === 5 && item.id === process.step && (
						<>
							<div className={styles.stepContent}>
								<p>
									{t('onboarding.steps.agreement.signAgreementActive')}
								</p>
							</div>
							<div className={styles.stepContent}>
								<OnboardingAgreement
									category={process.category}
									legacySign
									setCheckOnboarding={setCheckOnboarding}
								/>
							</div>
						</>
					)}
				</li>
			))}
			<QuestionnaireAccountClosure
				isVisible={accountClosureVisible && !accountClosureClosedAccount && !user.account_closure}
				onClose={handleAccountClosureClose}
				onSuccess={handleAccountClosureSuccess}
				userCurrency={user.currency_code}
			/>
			<QuestionnaireClosedAccount
				accountUrl={accountUrl}
				isVisible={user.account_closure}
			/>
		</ul>
	);
}

SignAgreementSteps.propTypes = {
	accountUrl: PropTypes.string.isRequired,
	blogPostEsgRegulationUrl: PropTypes.string,
	faqUrl: PropTypes.string.isRequired,
	process: PropTypes.shape({
		category: PropTypes.string,
		investmentQuestionnaireResetAvailable: PropTypes.bool,
		investorProfileId: PropTypes.number,
		questionnaire: PropTypes.string,
		questionnaires: PropTypes.arrayOf(PropTypes.shape({
			questionnaire: PropTypes.string.isRequired,
			finished: PropTypes.bool.isRequired,
		}).isRequired),
		step: PropTypes.number,
	}).isRequired,
	setCheckOnboarding: PropTypes.func.isRequired,
};

SignAgreementSteps.defaultProps = {
	blogPostEsgRegulationUrl: null,
};
