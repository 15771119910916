import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '../../../contexts/LocaleContext';
import {
	clientCheckSaveInvestmentAnswer,
	fetchClientCheckInvestmentQuestionnaire,
} from '../../../utils/api';
import { useFetchWithHardReload } from '../../../utils/useFetchWithReload';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Button } from '../../ui';
import AllPortfoliosCheck from '../AllPortfoliosCheck';
import Questionnaire from '../Questionnaire';

import styles from './InvestmentQuestionnaire.module.scss';

const useFetchInvestmentQuestionnaire = useFetchWithHardReload(fetchClientCheckInvestmentQuestionnaire);

const initialData = {
	questionnaire: [],
	lastAnsweredQuestionId: null,
	skippedQuestionIds: [],
	usPerson: false,
	investorProfile: {},
};

export default function InvestmentQuestionnaire({
	blogPostEsgRegulationUrl,
	childId,
	closeAccount,
	disabled,
	faqUrl,
	investmentQuestionnaireResetAvailable,
	overwriteEsgPreferenceAnswers,
	reloadClientCheck,
	resetEsgPreferenceQuestionnaire,
}) {
	const language = useLanguage();
	const [t] = useTranslation();
	const [started, setStarted] = useState(false);
	const [
		investmentQuestionnaireData,
		loading,
		error,,
		reloadQuestionnaire,
	] = useFetchInvestmentQuestionnaire(initialData, language, childId);
	const saveInvestmentAnswerAuth = useRequestAuth(clientCheckSaveInvestmentAnswer);

	if (loading || error) {
		return null;
	}

	const { investorProfiles: investorProfileData = null, ...questionnaireData } = investmentQuestionnaireData;

	return (
		<>
			<p className={styles.text}>
				{t('clientCheck.questionnaire.investment.text')}
			</p>
			<p className={styles.text}>
				<svg
					height={18}
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 576 512"
				>
					<path
						fill="currentColor"
						d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
					/>
				</svg>
				{t('clientCheck.questionnaire.investment.warning')}
			</p>
			{questionnaireData.questionnaire.length > 0 && (!started ? (
				<Button label={t('clientCheck.questionnaire.investment.start')} onClick={() => setStarted(true)} outline />
			) : (
				<Questionnaire
					blogPostEsgRegulationUrl={blogPostEsgRegulationUrl}
					childId={childId}
					closeAccount={closeAccount}
					disabled={disabled}
					faqUrl={faqUrl}
					investmentQuestionnaireResetAvailable={investmentQuestionnaireResetAvailable}
					isStarted={started}
					overwriteEsgPreferenceAnswers={overwriteEsgPreferenceAnswers}
					questionnaireData={questionnaireData}
					reloadQuestionnaire={reloadQuestionnaire}
					resetEsgPreferenceQuestionnaire={resetEsgPreferenceQuestionnaire}
					saveQuestionnaireAnswer={saveInvestmentAnswerAuth}
					type="investment"
				/>
			))}
			{questionnaireData.questionnaire.length === 0 && investorProfileData !== null && (
				<AllPortfoliosCheck
					childId={childId}
					closeAccount={closeAccount}
					disabled={disabled}
					faqUrl={faqUrl}
					investmentQuestionnaireResetAvailable={investmentQuestionnaireResetAvailable}
					investorProfileData={investorProfileData}
					reloadClientCheck={reloadClientCheck}
					reloadQuestionnaire={reloadQuestionnaire}
				/>
			)}
		</>
	);
}

InvestmentQuestionnaire.propTypes = {
	blogPostEsgRegulationUrl: PropTypes.string,
	childId: PropTypes.number,
	closeAccount: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	faqUrl: PropTypes.string.isRequired,
	investmentQuestionnaireResetAvailable: PropTypes.bool.isRequired,
	overwriteEsgPreferenceAnswers: PropTypes.func.isRequired,
	reloadClientCheck: PropTypes.func.isRequired,
	resetEsgPreferenceQuestionnaire: PropTypes.func.isRequired,
};

InvestmentQuestionnaire.defaultProps = {
	blogPostEsgRegulationUrl: null,
	childId: null,
	disabled: false,
};
