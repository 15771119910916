import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import webalize from '../../../utils/webalize';

import BlogPost from '../BlogPost';
import BlogPostBox from '../BlogPost/BlogPostBox';
import BlogPostContent from '../BlogPost/BlogPostContent';
import BlogPostImage from '../BlogPost/BlogPostImage';
import BlogPostList from '../BlogPost/BlogPostList';
import BlogPostTable from '../BlogPost/BlogPostTable';

import eggsImage from './images/etf/all-eggs-in-one-basket.png';
import eggsImage2x from './images/etf/all-eggs-in-one-basket-2x.png';
import eggsImageWebP from './images/etf/all-eggs-in-one-basket.webp';
import eggsImage2xWebP from './images/etf/all-eggs-in-one-basket-2x.webp';
import eggsImageMobile from './images/etf/all-eggs-in-one-basket-mobile.png';
import eggsImage2xMobile from './images/etf/all-eggs-in-one-basket-2x-mobile.png';
import eggsImageWebPMobile from './images/etf/all-eggs-in-one-basket-mobile.webp';
import eggsImage2xWebPMobile from './images/etf/all-eggs-in-one-basket-2x-mobile.webp';

import etfCreateImage from './images/etf/how-does-etf-create.png';
import etfCreateImage2x from './images/etf/how-does-etf-create-2x.png';
import etfCreateImageWebP from './images/etf/how-does-etf-create.webp';
import etfCreateImage2xWebP from './images/etf/how-does-etf-create-2x.webp';
import etfCreateImageMobile from './images/etf/how-does-etf-create-mobile.png';
import etfCreateImage2xMobile from './images/etf/how-does-etf-create-2x-mobile.png';
import etfCreateImageWebPMobile from './images/etf/how-does-etf-create-mobile.webp';
import etfCreateImage2xWebPMobile from './images/etf/how-does-etf-create-2x-mobile.webp';

import etfExampleImage from './images/etf/etf-example.png';
import etfExampleImage2x from './images/etf/etf-example-2x.png';
import etfExampleImageWebP from './images/etf/etf-example.webp';
import etfExampleImage2xWebP from './images/etf/etf-example-2x.webp';
import etfExampleImageMobile from './images/etf/etf-example-mobile.png';
import etfExampleImage2xMobile from './images/etf/etf-example-2x-mobile.png';
import etfExampleImageWebPMobile from './images/etf/etf-example-mobile.webp';
import etfExampleImage2xWebPMobile from './images/etf/etf-example-2x-mobile.webp';

import etfGraphImage from './images/etf/etf-graph.png';
import etfGraphImageWebP from './images/etf/etf-graph.webp';
import etfGraphImageMobile from './images/etf/etf-graph-mobile.png';
import etfGraphImage2xMobile from './images/etf/etf-graph-2x-mobile.png';
import etfGraphImageWebPMobile from './images/etf/etf-graph-mobile.webp';
import etfGraphImage2xWebPMobile from './images/etf/etf-graph-2x-mobile.webp';

import fundImage from './images/etf/what-is-fund.png';
import fundImage2x from './images/etf/what-is-fund-2x.png';
import fundImageWebP from './images/etf/what-is-fund.webp';
import fundImage2xWebP from './images/etf/what-is-fund-2x.webp';
import fundImageMobile from './images/etf/what-is-fund-mobile.png';
import fundImage2xMobile from './images/etf/what-is-fund-2x-mobile.png';
import fundImageWebPMobile from './images/etf/what-is-fund-mobile.webp';
import fundImage2xWebPMobile from './images/etf/what-is-fund-2x-mobile.webp';

import howToBuyImage from './images/etf/how-to-buy.png';
import howToBuyImage2x from './images/etf/how-to-buy-2x.png';
import howToBuyImageWebP from './images/etf/how-to-buy.webp';
import howToBuyImage2xWebP from './images/etf/how-to-buy-2x.webp';
import howToBuyImageMobile from './images/etf/how-to-buy-mobile.png';
import howToBuyImage2xMobile from './images/etf/how-to-buy-2x-mobile.png';
import howToBuyImageWebPMobile from './images/etf/how-to-buy-mobile.webp';
import howToBuyImage2xWebPMobile from './images/etf/how-to-buy-2x-mobile.webp';

import previewImage from './images/etf/preview.png';
import previewImage2x from './images/etf/preview-2x.png';
import previewImage2xWebp from './images/etf/preview-2x.webp';
import previewImageWebp from './images/etf/preview.webp';

import sapGraphImage from './images/etf/s&p-graph.png';
import sapGraphImageWebP from './images/etf/s&p-graph.webp';
import sapGraphImageMobile from './images/etf/s&p-graph-mobile.png';
import sapGraphImage2xMobile from './images/etf/s&p-graph-2x-mobile.png';
import sapGraphImageWebPMobile from './images/etf/s&p-graph-mobile.webp';
import sapGraphImage2xWebPMobile from './images/etf/s&p-graph-2x-mobile.webp';

import whatIsEtfImage from './images/etf/what-is-etf.png';
import whatIsEtfImage2x from './images/etf/what-is-etf-2x.png';
import whatIsEtfImageWebP from './images/etf/what-is-etf.webp';
import whatIsEtfImage2xWebP from './images/etf/what-is-etf-2x.webp';
import whatIsEtfImageMobile from './images/etf/what-is-etf-mobile.png';
import whatIsEtfImage2xMobile from './images/etf/what-is-etf-2x-mobile.png';
import whatIsEtfImageWebPMobile from './images/etf/what-is-etf-mobile.webp';
import whatIsEtfImage2xWebPMobile from './images/etf/what-is-etf-2x-mobile.webp';

const title = 'Co to jsou ETF, proč by Vás měla zajímat a\u00a0co o\u00a0nich musíte vědět?';
const slug = `${webalize(title)}-2022`;

const date = '2022-02-08';
const topic = 'ETF';
const updated = false;

const perex = 'Burzovně obchodované fondy neboli ETF (exchange–traded funds), si v\u00a0posledních letech '
	+ 'u\u00a0investorů vydobyly nevídanou popularitu a\u00a0v\u00a0médiích i\u00a0ve veřejné diskuzi o\u00a0nich slýcháme '
	+ 'čím dál častěji. Důvod je jednoduchý\u00a0– jedná se o\u00a0druh investice, který umožňuje i\u00a0nezkušenému '
	+ 'investorovi snadno a\u00a0s\u00a0nízkými náklady získat diverzifikovanou \u201esadu\u201c aktiv a\u00a0s\u00a0relativně nízkým '
	+ 'rizikem a\u00a0minimální kolísavostí zhodnocovat své peníze. Co to ale ta \u201eétéefka\u201c '
	+ 'vlastně jsou? A\u00a0jak fungují?';

const content = [
	'Co je to fond',
	'Co je to index',
	'Co je to ETF',
	'Jaké jsou druhy ETF',
	'Jak ETF vzniká',
	'Proč jsou ETF ideální volbou pro začínající investory',
	'Jak velký je trh s ETF',
	'ETF jako mimořádně cenově dostupná investice',
	'„Total Expense Ratio“ aneb kolik tedy ETF vlastně stojí',
	'Výhody a nevýhody ETF',
	'Jak ETF nakoupit',
];

const slugs = content.map((section) => webalize(section));

const listEFT = [
	'ETF je fond, podobně jako například podílový fond',
	'ETF se obchoduje na burze, podobně jako akcie',
	'ETF v\u00a0sobě obsahuje desítky, stovky až tisíce různých cenných papírů, nejčastěji akcií a\u00a0dluhopisů '
	+ 'takže investor nemusí nakupovat každý cenný papír zvlášť',
	'ETF kopíruje podkladový index, takže je plně automatizovaný a\u00a0levný',
];

const listFTSE100 = [
	'Index FTSE\u00a0100 byl spuštěn 3.\u00a0ledna\u00a01984.',
	'Index obsahuje 100 největších britských firem obchodovaných na londýnské burze.',
	'Výchozí hodnota indexu byla stanovena na 1\u00a0000 jednotek.',
	'S tím, jak se souhrnná tržní kapitalizace firem v\u00a0indexu každý obchodní den mění vůči původní '
	+ 'hodnotě 1\u00a0000, mění se i\u00a0hodnota indexu FTSE\u00a0100.',
	'Ačkoli o\u00a0situaci na londýnské burze uceleněji informuje index FTSE\u00a0All–Share, index FTSE\u00a0100 je '
	+ 'nejrozšířenějším indikátorem vývoje londýnského akciového trhu.',
	'Zařazování akcií do indexu FTSE\u00a0100 probíhá čtvrtletně.',
];

const listSAP500 = [
	'Historie akciového indexu S&P\u00a0500 (Standard\u00a0&\u00a0Poor\u2019s 500) sahá až do roku 1923, kdy začala '
	+ 'společnost Standard Statistics vydávat index sledující vývoj několika málo akcií. Současný index '
	+ 'S&P\u00a0500 se ale objevil až 4.\u00a0března\u00a01957. ',
	'Index S&P\u00a0500 zahrnuje 500 největších burzovně obchodovaných podniků v\u00a0USA listovaných na '
	+ 'burzách NYSE nebo NASDAQ.',
	'V indexu jsou zastoupeny jen takové firmy, které mají tržní kapitalizaci vyšší než 11.8\u00a0miliardy '
	+ 'amerických dolarů (tzv. large-cap) a\u00a0zároveň splňují i\u00a0další kritéria.',
	'S&P\u00a0500 je jeden z\u00a0nejsledovanějších akciových indexů na světě a\u00a0je považován za nejlepší '
	+ 'ukazatel vývoje americké ekonomiky.',
	'Zařazování akcií do indexu probíhá čtvrtletně. Akcie jsou váženy dle tržního ocenění.',
];

const listTypes = [
	'Akciová \u2013 sledují akciový index, příkladem může být SPDR S&P\u00a0500 (SPY)',
	'Dluhopisová \u2013 sledují dluhopisový koš, který je průběžně obměňován podle toho, '
	+ 'kdy který dluhopis dojde splatnosti. Příkladem může být SPDR Capital Long Credit Bond ETF (LWC)',
	'Sektorová \u2013 sledují sektorový index určitého odvětví, příkladem může být Invesco Dynamic Pharmaceuticals ETF (PJP)',
	'Měnová \u2013 kopírují relativní hodnotu měny, nebo koše více měn a\u00a0mohou se využívat ke spekulaci '
	+ 'na růst nebo pokles jejich hodnoty, k\u00a0diverzifikaci portfolia nebo k\u00a0měnovému zajištění. '
	+ 'Příkladem může být Invesco CurrencyShares Euro Currency Trust (FXE)',
	'Komoditní \u2013 mohou být podobná jako sektorová ETF, kdy sektorem je například těžařský průmysl, '
	+ 'ale existují i\u00a0ETF na konkrétní komodity. Je ovšem důležité vědět, že dané ETF komodity fyzicky nedrží, '
	+ 'nýbrž pouze vstupuje do derivátových obchodů, které kopírují vývoj dané komodity. '
	+ 'Příkladem může být SPDR Gold Shares.',
];

const listSpecials = [
	'Inverzní \u2013 jsou založené na shortování (spekulaci na pokles ceny), takže jejich hodnota roste, '
	+ 'když podkladový index klesá.',
	'Páková \u2013 využívají finanční deriváty a\u00a0násobně zvyšují dopad růstu nebo poklesu podkladového '
	+ 'indexu. Například pákové ETF 2\u00a0:\u00a01 znamená, že hodnota ETF vzroste nebo klesne dvakrát více, '
	+ 'než je růst nebo pokles podkladového indexu.',
	'Aktivně spravovaná \u2013 spojuje výhody aktivně spravovaného podílového fondu a\u00a0burzovně '
	+ 'obchodovaného fondu.',
	'Dividendová \u2013 zahrnují akcie s\u00a0vysokým dividendovým výnosem. ',
];

const listETFMarket = [
	'zhruba tak velký jako třetina amerického HDP v\u00a0roce 2020',
	'30\u00d7 větší než HDP České republiky v\u00a0roce 2020',
	'4\u00d7 větší než tržní kapitalizace Applu v\u00a0roce 2020',
	'3\u00d7 větší než hodnota všech nemovitostí v\u00a0New Yorku v\u00a0roce 2020',
];

const listBenefits = [
	'ETF jsou <0>efektivní</0> z\u00a0hlediska nákladů. Provoz fondu je oproti aktivně spravovaným fondům výrazně levnější. '
	+ 'Většina aktivně spravovaných fondů přitom ve své snaze „porazit výkonnost trhu“ selhává a\u00a0v konečném důsledku '
	+ 'svou ziskovostí za většinou ETF zaostává.',
	'ETF jsou <0>transparentní</0> a\u00a0investor přesně ví, do čeho investuje. ETF kopírují složení a\u00a0vyvážení vybraného '
	+ 'indexu, a\u00a0investor si tedy může být jist, že výkonnost fondu bude odpovídat výkonnosti daného '
	+ 'indexu (po započtení poplatku TER, který jsme si vysvětlili výše).',
	'Fondy z\u00a0kategorie ETF jsou široce <0>diverzifikované</0>, protože součástí indexu jsou desítky, stovky až tisíce '
	+ 'cenných papírů. Investor tak může snadno nakoupit velmi diverzifikované portfolio.',
	'ETF jsou velmi <0>likvidní</0>, tedy směnitelné za hotovost. ETF můžete prodávat kdykoli během obchodních hodin '
	+ 'dané burzy cenných papírů. Jedná se tedy o\u00a0investici, v\u00a0rámci které nejsou prostředky investora nijak '
	+ 'vázány a\u00a0lze je okamžitě prodat za peníze odpovídající aktuálnímu tržnímu ocenění.',
	'ETF jsou <0>flexibilní</0>. Investor má na výběr z\u00a0nesmírně široké nabídky fondů zaměřených na konkrétní aktiva, '
	+ 'vybranou geografickou oblast, konkrétní sektor hospodářství a\u00a0podobně. Aktuálně je na světě na výběr '
	+ 'přes 7\u00a0000 indexových fondů.',
	'ETF mohou <0>vyplácet dividendy</0>\u00a0\u2013 vyplácí je celá řada fondů, ať už jsou zaměřené na nákup akcií, dluhopisů či '
	+ 'třeba nemovitostí. Investor tedy díky ETF může nakoupit příjmové portfolio.',
];

const listDrawbacks = [
	'Konkrétní ETF <0>mohou být riziková</0>. Stejně jako může aktivní správce fondu nakupovat špatné akcie, '
	+ 'ETF se může řídit indexem, který zahrnuje například firmy z\u00a0upadajícího odvětví. Je proto dobré si '
	+ 'důkladně rozmyslet, zda je daný indexový fond svým zaměřením perspektivní.',
	'Některé fondy <0>mohou mít nízkou likviditu</0>. Ačkoli to rozhodně není pravidlem a\u00a0likvidita je u\u00a0burzovně '
	+ 'obchodovaných fondů naopak jednou z\u00a0výhod, existují i\u00a0jistá malá či speciální ETF, '
	+ 'která jsou obchodovaná jen zřídka.',
	'ETF <0>neporazí trh</0>. Investor do ETF nikdy nedosáhne zázračných a\u00a0nadprůměrných výnosů. Výkonnost indexového '
	+ 'fondu totiž vždy odpovídá výkonnosti daného trhu, který je sledován použitým indexem. Aktivně spravovaný '
	+ 'fond naproti tomu může trh porážet, ač se to dle studií v\u00a0dlouhodobém měřítku děje jen velmi zřídka.',
];

export const data = {
	id: slug,
	date,
	languageVersions: {
		en: null,
	},
	perex,
	previewImage: {
		src: previewImage,
		src2x: previewImage2x,
		src2xWebP: previewImage2xWebp,
		srcWebP: previewImageWebp,
	},
	slug,
	title,
	topic,
	updated,
};

export default function PostETF({
	baseUrl,
	blogUrl,
	homepageUrl,
	shareUrl,
}) {
	return (
		<BlogPost
			blogUrl={blogUrl}
			date={date}
			metaImage={`${baseUrl}/images/blog/fondee-og-blog-etf.png`}
			perex={perex}
			shareUrl={shareUrl}
			title={title}
			topic={topic}
			updated={updated}
		>
			<BlogPostContent content={content} slugs={slugs} />
			<p>
				Jak píše například Robinhood, ETF je jako smoothie. Vezmete spoustu akcií, dluhopisů i&nbsp;dalších
				aktiv (třeba komodit, měn nebo nemovitostí), smícháte je dohromady a&nbsp;vznikne jeden jediný produkt,
				ve kterém ale najdete kousek od každé vložené ingredience.
			</p>
			<p>
				Když si chcete dát smoothie, můžete si koupit banán a&nbsp;další ovoce, ovesné vločky, mléko, oříšky…
				A&nbsp;vše si doma pečlivě připravit. Nejdřív ale budete muset dojít do obchodu, potraviny nakoupit,
				přinést domů a&nbsp;doma je rozmixovat. Nebo můžete dojít do obchodu a&nbsp;rovnou si koupit
				třeba jogurtové smoothie, což je o&nbsp;dost jednodušší proces.
				A&nbsp;přesně tak to funguje i&nbsp;s&nbsp;ETF. Pokud chcete své peníze investovat do
				akcií a&nbsp;dluhopisů, není nic jednoduššího než si koupit ETF. V&nbsp;jednom produktu tak
				získáte od všeho trochu.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={etfExampleImageMobile}
				src2x={etfExampleImage2xMobile}
				srcWebP={etfExampleImageWebPMobile}
				src2xWebP={etfExampleImage2xWebPMobile}
				srcBreakpoint={etfExampleImage}
				srcBreakpoint2x={etfExampleImage2x}
				srcBreakpointWebP={etfExampleImageWebP}
				srcBreakpoint2xWebP={etfExampleImage2xWebP}
				alt="Co je to ETF?"
			/>
			<p>
				Necháme-li metafory stranou, můžeme ETF popsat i&nbsp;takto: jedná se o&nbsp;burzovně obchodované fondy,
				které sledují jasně dané množství akcií, dluhopisů či jiných aktiv a&nbsp;umožňují investorovi hromadně
				investovat do celé skupiny těchto aktiv. ETF většinou sledují konkrétní benchmark, tedy určitý koš
				aktiv, kterým je typicky tržní index. Díky tomu je pro investora eliminována nutnost
				vybírat si konkrétní aktiva, jako jsou třeba akcie nebo dluhopisy jedné konkrétní firmy.
				Složení fondu je totiž jasně dáno indexem.
			</p>
			<BlogPostList list={listEFT} />
			<p>
				Abychom lépe pochopili, jak ETF funguje, bude dobré si vysvětlit, co jsou vlastně
				{' '}
				<strong>fondy</strong>
				{' '}
				a&nbsp;co znamená právě onen
				{' '}
				<strong>index</strong>
				, který je podkladem pro ETF.
			</p>
			<h2 id={slugs[0]}>Co je to fond?</h2>
			<p>
				Fond je jakousi sbírkou investic konkrétní skupiny investorů, kteří své peníze investují společně.
				Fond si lze představit jako skupinu lidí (investorů), kteří své peníze vezmou a&nbsp;dají
				na jednu hromádku (či přesněji na jeden bankovní účet). Tato skupina si určí někoho, kdo bude správcem
				těchto nahromaděných prostředků&nbsp;&ndash; a&nbsp;takovému člověku se pak říká portfolio manažer
				nebo jednoduše správce fondu.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={fundImageMobile}
				src2x={fundImage2xMobile}
				srcWebP={fundImageWebPMobile}
				src2xWebP={fundImage2xWebPMobile}
				srcBreakpoint={fundImage}
				srcBreakpoint2x={fundImage2x}
				srcBreakpointWebP={fundImageWebP}
				srcBreakpoint2xWebP={fundImage2xWebP}
				alt="Co je to fond?"
			/>
			<p>
				Správce fondu následně společně nahromaděné prostředky vezme a&nbsp;investuje je do konkrétních aktiv,
				přičemž takovými aktivy mohou být akcie, dluhopisy, komodity, zahraniční měny,
				nemovitosti a&nbsp;podobně. Z&nbsp;těchto aktiv, do kterých fond investuje, pak každý z&nbsp;investorů
				fondu vlastní odpovídající podíl, jehož velikost je úměrně dána velikostí jeho vkladu na
				společnou &bdquo;hromadu peněz&ldquo;.
			</p>
			<p>
				Každý investiční fond má svou investiční strategii, a&nbsp;je tedy přesně stanoveno, do
				jakého typu aktiv může správce fondu peníze investovat, kdy může nakupovat nová aktiva, kdy
				je může prodávat a&nbsp;podobně.
				V&nbsp;investiční strategii fondu je rovněž definováno, jak vysoká je odměna pro portfolio
				manažera či kolik prostředků může být spotřebováno na transakční poplatky.
				Takovými poplatky mohou být třeba náklady na brokera, přes kterého fond nakupuje akcie.
			</p>
			<BlogPostBox>
				<h2>Ochrana investora</h2>
				<p>
					Dobrou zprávou pro každého podezíravého investora je fakt, že investiční fond není jen onou hromadou
					peněz se svým správcem, ale je také pečlivě regulovaným právním rámcem, který investory chrání
					před podvodem. Aby správce fondu nemohl jednoduše &bdquo;shrábnout&ldquo; nashromážděné
					peníze a&nbsp;odebrat se s&nbsp;nimi do teplých krajů, legislativa fond svazuje různými
					preventivními opatřeními.
				</p>
				<p>
					Takovým opatřením je například udělení licence k&nbsp;výkonu činnosti nebo fakt, že nashromážděné
					investice &bdquo;nevlastní&ldquo; onen správce fondu, ale oddělená právní entita. Správce fondu
					zkrátka není držitelem prostředků uvnitř fondu, ale pouze manažerem aktiv a&nbsp;financí, které
					investoři alokovali na onen společný účet.
				</p>
			</BlogPostBox>
			<p>
				Důležité je si uvědomit, že jelikož jsou aktiva nakupována pro celý fond, každý investor
				vlastní určitý zlomek souhrnu všech aktiv, která fond nakoupil. Pokud je tedy ve společném
				&bdquo;koši&ldquo; aktiv například 1&nbsp;000 akcií, konkrétní jedinec nevlastní například
				10&nbsp;konkrétních akcií z&nbsp;onoho tisíce, ale vlastní 1&nbsp;% z&nbsp;každé nakoupené akcie.
			</p>
			<p>
				Od počátku existence fondu je celková hodnota všech investic oceněna a&nbsp;rozdělena na konkrétní
				počet dílů&nbsp;&ndash; takzvaných
				{' '}
				<strong>podílových listů.</strong>
				{' '}
				Každý investor tak vlastní jasně definovaný počet jednotek dané investice, která odpovídá jeho vkladu.
			</p>
			<BlogPostBox>
				<h2>Snadno si to lze představit na následujícím příkladu:</h2>
				<p>
					Celkem 50&nbsp;investorů do fondu zainvestuje dohromady 1&nbsp;milion&nbsp;korun. Majetek fondu je
					následně rozdělen na milion jednotek neboli
					{' '}
					<strong>podílových listů</strong>
					{' '}
					a&nbsp;každý má hodnotu 1&nbsp;korunu. S&nbsp;tím, jak se daří aktivům, do kterých je zainvestováno,
					souhrnná hodnota majetku fondu časem povyroste například o&nbsp;25&nbsp;%. Celková hodnota (valuace)
					fondu je tedy již 1&nbsp;250&nbsp;000&nbsp;korun, což zároveň znamená, že každý podílový list má
					nyní hodnotu 1,25&nbsp;koruny.
				</p>
			</BlogPostBox>
			<p>Když už víme, co je to fond, podívejme se na definici indexu.</p>
			<h2 id={slugs[1]}>Co je to index?</h2>
			<p>
				Ve světě fondů a&nbsp;investic se indexem rozumí jakýsi &bdquo;koš&ldquo; cenných papírů, který
				reprezentuje celý trh, nebo konkrétní oblast trhu. Index sleduje výkon tohoto trhu a&nbsp;slouží jako
				jeho vzorové měřítko.
			</p>
			<p>
				Budeme-li konkrétní, například index FTSE&nbsp;100 zahrnuje 100&nbsp;největších firem listovaných na
				londýnské burze. Jedním z&nbsp;nejznámějších indexů je pak třeba také S&P&nbsp;500, který sleduje výkon
				akcií 500&nbsp;největších amerických společností. Indexy se ale nemusí týkat jen akcií. Existují také
				indexy sledující výkon dluhopisů, komodit, nemovitostí či dokonce kryptoměn.
			</p>
			<p>
				Abychom si index ještě více přiblížili, podívejme se na jedny z&nbsp;nejznámějších akciových indexů
				ve větším detailu.
			</p>
			<BlogPostBox>
				<h2>FTSE 100</h2>
				<BlogPostList list={listFTSE100} />
			</BlogPostBox>
			<BlogPostBox>
				<h2>S&P 500</h2>
				<BlogPostList list={listSAP500} />
			</BlogPostBox>
			<p>
				Pokud jde o&nbsp;otázku, jak vlastně takový index vzniká, sestavují ho samy významné burzy
				cenných papírů, ratingové agentury či přímo nezávislé firmy, které se na jejich výpočet specializují.
				Mezi nejoblíbenější tvůrce indexů patří firmy MSCI, S&P, STOXX nebo FTSE.
			</p>
			<p>
				Indexů existuje mnoho a&nbsp;každý z&nbsp;nich má přesně daná pravidla, podle kterých jsou do něj
				vybírány konkrétní cenné papíry. Každý index je tedy indikátorem výkonnosti konkrétní třídy aktiv,
				které jsou voleny dle geografické příslušnosti, náležitosti do konkrétního ekonomického odvětví,
				dle velikosti tržní kapitalizace a&nbsp;podobně. Index tedy může sledovat například 100&nbsp;největších
				evropských firem z&nbsp;automobilového průmyslu, 50&nbsp;středně velkých firem z&nbsp;rozvojových
				oblastí, nebo třeba 100&nbsp;největších globálních společností zaměřených na obnovitelnou energii.
			</p>
			<BlogPostTable>
				<table>
					<thead>
						<tr>
							<th>Index</th>
							<th>Popis</th>
							<th>Metoda vyvažování</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>EURO STOXX 50</td>
							<td>50 největších evropských firem</td>
							<td>Index vážený dle tržní kapitalizace (hodnoty firmy)</td>
						</tr>
						<tr>
							<td>FTSE 100</td>
							<td>100 největších britských firem na londýnské burze</td>
							<td>Dle tržní kapitalizace</td>
						</tr>
						<tr>
							<td>S&P 500</td>
							<td>500 největších amerických firem</td>
							<td>Dle tržní kapitalizace</td>
						</tr>
						<tr>
							<td>Nasdaq Composite</td>
							<td>Všechny cenné papíry obchodované na burze Nasdaq</td>
							<td>Dle tržní kapitalizace</td>
						</tr>
						<tr>
							<td>Dow Jones Industrial Average</td>
							<td>30 největších amerických firem</td>
							<td>Cenově vážený index, kde vahou je cena dané akcie</td>
						</tr>
						<tr>
							<td>Nikkei 225</td>
							<td>225 největších japonských firem</td>
							<td>Cenově vážený index, kde vahou je cena dané akcie</td>
						</tr>
					</tbody>
				</table>
			</BlogPostTable>
			<p>
				Z&nbsp;charakteristik popsaných výše je jasné, že je index skvělým indikátorem hospodářské výkonnosti
				konkrétního odvětví, potažmo hospodářství jako celku. Důležitou součástí indexu je proto i&nbsp;přesné
				stanovení podílu jednotlivých firem uvnitř indexu, tedy vyvažování (anglicky rebalancing).
				Většina indexů vyvažování provádí dle tržní kapitalizace (hodnoty firmy), což je případ i&nbsp;námi
				popisovaných indexů FTSE&nbsp;100 a&nbsp;S&P&nbsp;500. K&nbsp;rebalancování dochází v&nbsp;konkrétním
				intervalu, například čtvrtletně.
			</p>
			<p>
				Důvod pro vyvažování indexů je jednoduchý&nbsp;&ndash; s&nbsp;tím, jak se mění hodnota jednotlivých
				firem, musí se měnit i&nbsp;jejich váha v&nbsp;rámci indexu. Firmy navíc do indexu přibývají
				a&nbsp;zase z&nbsp;něj mizí, tak, jak se mění jejich tržní kapitalizace daná součtem ceny
				všech vydaných akcií. Britská 101. největší firma například může v&nbsp;ocenění snadno překonat
				tu 100., která se do indexu při posledním vyvažování vešla jako úplně poslední, a&nbsp;tím se
				dostat na její místo.
			</p>
			<BlogPostBox info>
				<p>
					Rozdílná váha jednotlivých firem pro index v&nbsp;praxi znamená, že zažije-li nějaký větší výkyv
					největší firma zahrnutá v&nbsp;indexu, na výkonnosti daného indexu se to viditelně projeví.
					Pokud ke stejnému výkyvu dojde u&nbsp;100. největší firmy, tedy u&nbsp;té nejmenší z&nbsp;celého
					indexu, hodnotou indexu to samo o&nbsp;sobě příliš nepohne. Aby byl ale index spolehlivým
					indikátorem výkonu daného trhu či odvětví, je nutné jej pravidelně vyvažovat
					a&nbsp;aktualizovat&nbsp;&ndash; dle pevně stanovených pravidel daného indexu.
				</p>
			</BlogPostBox>
			<BlogPostImage
				breakpoint="md"
				src={sapGraphImageMobile}
				src2x={sapGraphImage2xMobile}
				srcWebP={sapGraphImageWebPMobile}
				src2xWebP={sapGraphImage2xWebPMobile}
				srcBreakpoint={sapGraphImage}
				srcBreakpointWebP={sapGraphImageWebP}
				alt="Vývoj indexu S&P 500 - zdroj: finance.yahoo.com"
				captionVisible
			/>
			<h2 id={slugs[2]}>Co to je ETF?</h2>
			<p>
				Když nyní víme, co to je fond, a&nbsp;vysvětlili jsme si rovněž, co to je index, zbývá nám už jen si
				vyjasnit, co se míní pojmem ETF. Jak již bylo nastíněno v&nbsp;úvodu a&nbsp;jak zároveň vyplývá
				z&nbsp;vysvětlení pojmů fond a&nbsp;index, ETF jsou investiční fondy, jejichž složení je přesně
				specifikované konkrétním benchmarkem, ve většině případů tržním indexem. Správce daného fondu má tedy
				jedinou úlohu&nbsp;&ndash; zajistit, aby fond co nejpřesněji kopíroval vývoj daného
				reprezentativního indexu.
			</p>
			<p>
				Tyto indexové fondy mají pro investora velkou výhodu v&nbsp;tom, že jsou dokonale transparentní.
				Investor při pohledu na daný index snadno zjistí, do jakých konkrétních aktiv své peníze vkládá,
				jaký je historický vývoj výkonnosti daného indexu a&nbsp;podobně. Indexové fondy jsou navíc odolné vůči
				lidským chybám na straně správce, protože složení fondu je jasně dané indexem, který je do značné
				míry nezávislým a&nbsp;&bdquo;bezcitným&ldquo; tržním indikátorem.
			</p>
			<p>
				Důležitou charakteristikou ETF je pak také to, že se obchodují na burze, stejně jako například akcie.
				Výhodou je tedy dostupnost těchto fondů, protože k&nbsp;získání jejich podílových listů stačí využít
				služby prakticky jakéhokoli brokera. Stejně snadno lze podílové listy i&nbsp;prodávat a&nbsp;okamžitě je
				směnit zpět na hotovost odpovídající jejich aktuální hodnotě.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={whatIsEtfImageMobile}
				src2x={whatIsEtfImage2xMobile}
				srcWebP={whatIsEtfImageWebPMobile}
				src2xWebP={whatIsEtfImage2xWebPMobile}
				srcBreakpoint={whatIsEtfImage}
				srcBreakpoint2x={whatIsEtfImage2x}
				srcBreakpointWebP={whatIsEtfImageWebP}
				srcBreakpoint2xWebP={whatIsEtfImage2xWebP}
				alt="Jak ETF vzniká?"
			/>
			<h2 id={slugs[3]}>Jaké jsou druhy ETF?</h2>
			<h3>ETF v&nbsp;sobě mohou obsahovat různé cenné papíry, a&nbsp;podle toho je dělíme na ETF:</h3>
			<BlogPostList list={listTypes} />
			<h3>
				Dále existují velmi specializované typy ETF, se kterými se již tak často nesetkáme.
				Příkladem mohou být ETF:
			</h3>
			<BlogPostList list={listSpecials} />
			<h2 id={slugs[4]}>Jak ETF vzniká?</h2>
			<p>
				Pro názorné vysvětlení toho, jak ETF vzniká, si vezměme příklad sektorového ETF, tedy fondu složeného z
				akcií jednoho konkrétního hospodářského sektoru. Společnost spravující investiční fondy, jako třeba
				BlackRock nebo Invesco, nakupuje akcie hromadně. Zatímco vy byste najednou nakoupili 10 či třeba 50
				akcií firmy, BlackRock jich jednorázově nakoupí násobně vyšší množství, třeba tisíc či milion.
				Řekněme tedy, že taková investiční společnost nakoupí 500 tisíc akcií hotelů Hilton,
				400 tisíc akcií hotelů Marriott a&nbsp;400 tisíc akcií hotelů Hyatt. Firma jako BlackRock
				či Invesco všechny tyto akcie vezme, dá je dohromady a&nbsp;vytvoří ETF nazvané třeba
				&bdquo;Large Hotel ETF&ldquo;. Výsledný fond je poté rozdělen na malé kousky, které jsou následně
				umístěny na burzu a&nbsp;lze je snadno nakoupit,
				stejně jako jednotlivé akcie. Investor tak může snadno jediným nákupem získat podíl ve všech firmách z
				dané skupiny, tedy v&nbsp;hotelech Hilton, Marriott i&nbsp;Hyatt. Náš hotelový příklad s&nbsp;třemi
				firmami slouží jako zjednodušená ilustrace. Ve skutečnosti ETF obsahují vyšší desítky,
				stovky nebo tisíce titulů.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={etfCreateImageMobile}
				src2x={etfCreateImage2xMobile}
				srcWebP={etfCreateImageWebPMobile}
				src2xWebP={etfCreateImage2xWebPMobile}
				srcBreakpoint={etfCreateImage}
				srcBreakpoint2x={etfCreateImage2x}
				srcBreakpointWebP={etfCreateImageWebP}
				srcBreakpoint2xWebP={etfCreateImage2xWebP}
				alt="Jak ETF vzniká?"
			/>
			<h2 id={slugs[5]}>Proč jsou ETF ideální volbou pro začínající investory</h2>
			<p>
				Indexové burzovně obchodované fondy si své místo na slunci zajisté vybojovaly i&nbsp;proto, že
				jsou mimořádně vhodné i&nbsp;pro začínající investory, jejichž poptávka po smysluplné ochraně peněz
				před inflací strmě roste. Kdokoli začne uvažovat nad tím, že by nebylo od věci si nějaký ten peníz
				odkládat stranou, brzy přijde na to, že úrokové sazby na spořicích účtech jsou natolik nízké, že ani
				zdaleka nebrání peníze před ztrátou reálné hodnoty, takže logickou alternativou je nějaká forma
				investic. Jenže vstup do světa obchodování s&nbsp;cennými papíry se laikovi může jevit velmi složitým.
			</p>
			<p>
				Jakmile člověk začne nad investicemi přemýšlet a&nbsp;dohledávat si základní informace, dozví se, že
				nejzákladnější poučkou pro investora je, aby diverzifikoval portfolio. Co to znamená česky?
				Pokud investor nechce podstupovat neúměrné riziko ztráty, rozhodně by neměl vsadit vše na jednu kartu.
				Vložit &bdquo;všechna svá vajíčka do jednoho košíčku&ldquo; se jednoduše nevyplácí.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={eggsImageMobile}
				src2x={eggsImage2xMobile}
				srcWebP={eggsImageWebPMobile}
				src2xWebP={eggsImage2xWebPMobile}
				srcBreakpoint={eggsImage}
				srcBreakpoint2x={eggsImage2x}
				srcBreakpointWebP={eggsImageWebP}
				srcBreakpoint2xWebP={eggsImage2xWebP}
				alt="Proč jsou ETF ideální volbou pro začínající investory"
			/>
			<p>
				Bohužel právě výběr správných aktiv je na investování to složité. Zároveň je to ale to, co elegantně
				řeší investice do zmíněných fondů ETF. Riziko toho, že investice &bdquo;dojede&ldquo; na špatný úsudek
				investora, ETF do značné míry eliminují, protože se jedná o&nbsp;&bdquo;koktejl&ldquo; mnoha titulů,
				které jsou do daného fondu vybrány podle konkrétního indexu. V&nbsp;praxi to znamená, že
				v&nbsp;portfoliu budou zastoupeny jak tituly, kterým se bude dařit jen podprůměrně, tak i&nbsp;tituly
				firem s&nbsp;nadprůměrným výkonem, jejichž hodnota poletí nahoru. Riziko je tedy moudře rozloženo.
			</p>
			<h2 id={slugs[6]}>Jak velký je trh s&nbsp;ETF?</h2>
			<p>
				Z&nbsp;výše popsaných důvodů burzovně obchodované fondy získávají rychle na popularitě a&nbsp;množství
				do nich zainvestovaných prostředků zejména v&nbsp;posledních letech raketově letí nahoru. Již ke konci
				roku 2020 investoři do těchto fondů nalili dohromady přes 7 bilionů dolarů.
			</p>
			<p>Pro představu, objem peněz celosvětově investovaných do ETF je</p>
			<BlogPostList list={listETFMarket} />
			<p>
				Toto číslo se zdá jako skutečně enormní. Na druhou stranu jde jen o&nbsp;přibližně osminu celosvětového
				objemu investic spravovaných podílovými fondy.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={etfGraphImageMobile}
				src2x={etfGraphImage2xMobile}
				srcWebP={etfGraphImageWebPMobile}
				src2xWebP={etfGraphImage2xWebPMobile}
				srcBreakpoint={etfGraphImage}
				srcBreakpointWebP={etfGraphImageWebP}
				alt="Množství peněz zainvestovaných do ETF - zdroj: www.statista.com"
				captionVisible
			/>
			<h2 id={slugs[7]}>ETF jako mimořádně cenově dostupná investice</h2>
			<p>
				Cenová úspora oproti jiným investicím vzniká u&nbsp;ETF logicky už tím, že investor nepotřebuje vkládat
				čas do neustálého sledování trhu a&nbsp;výběrem těch správných akcií, dluhopisů, komodit či třeba
				kryptoměn, aby zainvestoval &bdquo;správně&ldquo;. Jelikož investor nákupem diverzifikovaného portfolia
				docílí koupě podílu v&nbsp;jediném fondu, má svou investici &bdquo;bez práce&ldquo;.
			</p>
			<p>
				Nejde ale jen o&nbsp;úsporu časovou. Jelikož s&nbsp;ETF člověk nakoupí širokou škálu firem jediným
				příkazem, výrazně ušetří také na poplatcích za obchodování. Při koupi jednotlivých akcií,
				dluhopisů a&nbsp;podobně stojí každý nákup určité množství peněz na transakčních poplatcích.
				Čím více jednotlivých titulů jedinec nakoupí, tím diverzifikovanější portfolio bude mít, ale tím
				víc také zaplatí na poplatcích za nákup pozic.
			</p>
			<p>
				Tento neduh u&nbsp;ETF odpadá&nbsp;&ndash; k&nbsp;nákupu celého portfolia člověku stačí jeden nákup.
				Poplatky fondů s&nbsp;označením ETF jsou obecně velmi nízké díky tomu, že fond hromadně nakupuje
				akcie a&nbsp;další aktiva pro tisíce svých investorů, takže poplatky se napříč fondem dobře rozloží.
			</p>
			<p>
				Nákladovost ETF je rovněž výrazně nižší než u&nbsp;aktivně spravovaných fondů, které nabízejí banky či
				investiční společnosti. U&nbsp;aktivně spravovaných fondů totiž portfolio aktivně obhospodařují experti,
				kteří neustále analyzují trh, aktiva ručně vybírají a&nbsp;aktivně s&nbsp;nimi obchodují.
			</p>
			<p>
				ETF jsou naproti tomu
				{' '}
				<strong>pasivními fondy</strong>
				, které, jak již bylo zmíněno, sledují konkrétní index, tedy
				zahrnují firmy, které odpovídají předem jasně určeným kritériím. Provoz takového fondu je samozřejmě
				levnější, protože nevyžaduje nepřetržitou správu. Aktivní zásahy spočívají pouze v&nbsp;tom, že jsou
				ETF v určitých časových intervalech &bdquo;aktualizovány&ldquo;, aby jejich obsah stále odpovídal
				sledovanému indexu a&nbsp;aby odpovídalo i&nbsp;vyvážení jednotlivých aktiv v&nbsp;rámci fondu.
				Zjednodušeně řečeno, ETF fondy jsou levné, protože nemusí živit aparát odborníků, kteří nad portfoliem
				neustále aktivně bdí.
			</p>
			<p>
				Abychom ale nezjednodušovali příliš, je dobré uvést, že ne všechna ETF jsou pasivně řízená.
				Ačkoli se ETF stalo v&nbsp;poslední době synonymem pasivního investování, nově vznikají i&nbsp;aktivně
				řízené ETF fondy. Stále ale představují jen zanedbatelnou část objemu peněz investovaných do ETF.
			</p>
			<BlogPostBox>
				<h2>Revoluce v&nbsp;investování</h2>
				<p>
					Abychom si to shrnuli, ETF jsou vhodná pro lidi, kteří chtějí rozložit riziko a&nbsp;najednou
					investovat do více různých aktiv. Investování skrze ETF je velmi jednoduché, vhodné i&nbsp;pro úplné
					začátečníky a&nbsp;co je důležité, jedná se také o&nbsp;investování mimořádně &bdquo;levné&ldquo;.
					V&nbsp;souvislosti s&nbsp;ETF se mluví dokonce o&nbsp;revoluci v&nbsp;oblasti soukromých investic,
					protože burzovně obchodované fondy výrazně přiblížily svět investic &bdquo;běžným&ldquo; lidem, pro
					které dříve bylo investování nedostupnou, drahou a&nbsp;složitou záležitostí.
				</p>
			</BlogPostBox>
			<h2 id={slugs[8]}>&bdquo;Total Expense Ratio&ldquo; aneb kolik tedy ETF vlastně stojí?</h2>
			<p>
				ETF tedy patří k&nbsp;těm vůbec nejlevnějším investicím. Co to ale znamená konkrétně?
				Nákladovost fondu je vždy určena parametrem TER (Total Expense Ratio). Tento parametr
				zahrnuje veškeré náklady spojené s&nbsp;provozem fondu vůči celkovému objemu portfolia, a&nbsp;vyjádřen
				je tedy v&nbsp;procentech. Do TER jsou zahrnuty opravdu všechny provozní náklady&nbsp;&ndash; od
				odměny pro správce fondu přes náklady na právní či auditorské služby až po transakční poplatky
				za nákup aktiv.
			</p>
			<p>
				Při nákupu ETF však bohužel nehraje roli jen nákladovost samotného fondu, ale také to, jaké
				náklady se pro samotného investora pojí s&nbsp;jeho nákupem. O&nbsp;nákupu ETF si ale ještě povíme…
				Mimochodem, když už je řeč o&nbsp;nákladovosti jednotlivých fondů a&nbsp;důvodech, proč jsou některé
				typy fondů levnější než jiné, za zmínku stojí následující. Povšimněte si, že burzovně obchodované
				fondy (ETF) mají pramalou oblibu mezi finančními poradci a&nbsp;bankéři žijícími z&nbsp;provize.
				Dáno je to tím, že zatímco u&nbsp;běžných fondů funguje provizní systém, který finanční poradce
				motivuje nabízet konkrétní fondy s&nbsp;ohledem na provizi za zprostředkování,
				u ETF nic takového zavedeno není.
			</p>
			<BlogPostBox>
				<h2>Větší fondy bývají levnější</h2>
				<p>
					Jelikož je parametr TER definován ve vztahu k&nbsp;celkové hodnotě fondu, levněji obvykle vyjdou ta
					největší ETF, u&nbsp;nichž dochází k&nbsp;nejvyšší úspoře z&nbsp;rozsahu&nbsp;&ndash; náklady se
					rozdrobí mezi vyšší počet investorů. Pozor ale na to, že ačkoli je výše TER vždy transparentně
					uvedena v&nbsp;dokumentaci daného ETF, ve výpisu již zaplacené poplatky nenaleznete. Je to dáno
					tím, že cena ETF je již automaticky snížena o&nbsp;TER, což rovnou snižuje výnos investice
					pro investora.
				</p>
			</BlogPostBox>
			<h2 id={slugs[9]}>Shrnutí výhod ETF:</h2>
			<BlogPostList list={listBenefits} />
			<h2>Možné nevýhody ETF aneb na co si dát pozor:</h2>
			<BlogPostList list={listDrawbacks} />
			<h2 id={slugs[10]}>Jak ETF nakoupit?</h2>
			<p>
				Jak již bylo řečeno, ETF jsou burzovně obchodované fondy, takže aby je investor mohl nakoupit,
				může podobně jako u&nbsp;akcií využít konkrétního brokera neboli obchodníka s&nbsp;cennými papíry.
				Portálů umožňujících obchodování s&nbsp;cennými papíry je celá řada, a&nbsp;to jak tuzemských,
				tak zahraničních. Brokeři investorům ve svých webových (a&nbsp;často i&nbsp;mobilních) aplikacích
				umožňují fondy ETF snadno nakoupit, a&nbsp;to úplně stejným způsobem, jako když se na
				burze nakupují akcie.
			</p>
			<p>
				Nevýhodou takového nákupu je to, že se investor nevyhne poměrně vysokým transakčním poplatkům, a
				nutné je poznamenat, že nákup přímo přes brokera pro začátečníka celkově nemusí být tou nejpříjemnější
				volbou. Investor totiž musí přesně vědět, jaké konkrétní ETF chce pořídit, což s&nbsp;sebou samozřejmě
				nese i&nbsp;časovou investici nutnou pro pečlivé nastudování nabídky těchto fondů. Aplikace pro
				obchodování mohou navíc pro nezkušeného investora působit velmi nesrozumitelně a&nbsp;složitě.
			</p>
			<BlogPostImage
				breakpoint="md"
				src={howToBuyImageMobile}
				src2x={howToBuyImage2xMobile}
				srcWebP={howToBuyImageWebPMobile}
				src2xWebP={howToBuyImage2xWebPMobile}
				srcBreakpoint={howToBuyImage}
				srcBreakpoint2x={howToBuyImage2x}
				srcBreakpointWebP={howToBuyImageWebP}
				srcBreakpoint2xWebP={howToBuyImage2xWebP}
				alt="Jak ETF nakoupit?"
			/>
			<p>
				Naštěstí existují digitální služby jako naše Fondee, které otevírají svět investic i&nbsp;menším
				investorům. Výhodou je, že Fondee dokáže ETF pro investory nejen nakoupit, ale především sestavit
				celé portfolio dle rizikového profilu každého investora, a&nbsp;navíc toto portfolio pravidelně
				rebalancovat. Investice je tak chytře složena a&nbsp;riziko rozloženo ještě účinněji než při
				nákupu jednoho konkrétního ETF.
			</p>
			<p>
				Pozitivem je, že se digitální platformy jako
				{' '}
				<Link to={homepageUrl}>Fondee</Link>
				{' '}
				mohou pochlubit ještě nižší nákladovostí,
				protože ETF nakupují hromadně, a&nbsp;rozloží tedy transakční poplatky mezi všechny
				investory využívající danou službu. Navíc mluví s&nbsp;uživateli běžně srozumitelnou řečí,
				nabízí přehledné rozhraní a&nbsp;investování tak činí dostupným opravdu pro každého.
			</p>
		</BlogPost>
	);
}

PostETF.propTypes = {
	baseUrl: PropTypes.string.isRequired,
	blogUrl: PropTypes.string.isRequired,
	homepageUrl: PropTypes.string.isRequired,
	shareUrl: PropTypes.string.isRequired,
};
