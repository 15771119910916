/* global document, window */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Container } from '../../../components/layout';
import { Badge, Loader, ScrollToTop } from '../../../components/ui';
import { useLanguage } from '../../../contexts/LocaleContext';
import BlogPostShare from './BlogPostShare';
import BlogPostReferences from './BlogPostReferences';

import styles from './BlogPost.module.scss';

const cleanSpaces = (content) => (content !== null ? content.replace(/\u00A0|&nbsp;/g, ' ') : null);

export default function BlogPost({
	blogUrl,
	children,
	date,
	metaDescription,
	metaImage,
	perex,
	perexRich,
	references,
	shareUrl,
	title,
	topic,
	updated,
}) {
	const [t] = useTranslation();
	const language = useLanguage();
	const [display, setDisplay] = useState(false);
	const metaTitle = cleanSpaces(`${title}${t('blogPost.meta.titleSuffix')}`);
	const metaDescriptionAttribute = cleanSpaces(metaDescription ?? perex);

	// do not display content on first render to prevent jump to bottom before scrolling to top
	useEffect(() => {
		const timeoutId = setTimeout(() => setDisplay(true), 100);
		return () => clearTimeout(timeoutId);
	}, []);

	useEffect(() => {
		const anchor = window.location.hash.split('#')[1];
		if (display) {
			setTimeout(() => {
				document.getElementById(anchor)?.scrollIntoView({
					behavior: 'smooth',
				});
			}, 700);
		}
	}, [display]);

	return (
		<article className={styles.root}>
			<Helmet>
				<title>{metaTitle}</title>
				<meta content={metaDescriptionAttribute} name="description" />
				<meta content={metaTitle} property="og:title" />
				<meta content={metaDescriptionAttribute} property="og:description" />
				{metaImage && <meta content={metaImage} property="og:image" />}
				{metaImage && <meta content={metaImage} name="twitter:image" />}
			</Helmet>
			<Container variant="narrow">
				<header>
					<Link to={blogUrl} className={styles.linkBack}>
						{t('blog.back')}
					</Link>
					<div className={styles.headerContent}>
						{topic && <Badge label={topic} />}
						<time className={styles.date} dateTime={date}>
							{updated && (
								<>
									{t('blogPost.updated')}
									{' '}
								</>
							)}
							{new Date(date).toLocaleString(language, { dateStyle: 'medium' })}
						</time>
						<h1 className={styles.title}>
							{title}
						</h1>
						{perexRich !== null ? (
							<div className={styles.perexRich}>
								{perexRich}
							</div>
						) : (perex && (
							<p className={styles.perex}>
								{perex}
							</p>
						))}
					</div>
				</header>
				<div className={styles.content}>
					{display ? children : <Loader />}
				</div>
				<BlogPostShare link={shareUrl} />
				{references && <BlogPostReferences references={references} />}
				<ScrollToTop title={t('scrollToTop.title')} />
			</Container>
		</article>
	);
}

BlogPost.propTypes = {
	blogUrl: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	date: PropTypes.string.isRequired,
	metaDescription: PropTypes.string,
	metaImage: PropTypes.string,
	perex: PropTypes.string,
	perexRich: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	references: PropTypes.arrayOf(PropTypes.string),
	shareUrl: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	topic: PropTypes.string,
	updated: PropTypes.bool,
};

BlogPost.defaultProps = {
	metaDescription: null,
	metaImage: null,
	perex: null,
	perexRich: null,
	references: null,
	topic: null,
	updated: false,
};
