import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCountry, useLanguage } from '../../../contexts/LocaleContext';
import {
	fetchInvestorProfile,
	fetchOnboardingInvestmentProfile,
	fetchShowEsgWarning,
	onboardingResetInvestmentQuestionnaire,
	saveOnboardingInvestorProfile,
} from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import useFetchAuth from '../../../utils/useFetchAuth';
import useRequestAuth from '../../../utils/useRequestAuth';
import { Button, Modal, Toggle } from '../../ui';
import InvestorProfileDetailSelection from '../InvestorProfileDetail/InvestorProfileDetailSelection';
import InvestorProfileDetailSlider from '../InvestorProfileDetail/InvestorProfileDetailSlider';

import styles from './InvestorProfileChoice.module.scss';

const useFetchInvestorProfile = useFetch(fetchInvestorProfile);
const useFetchOnboardingInvestmentProfile = useFetchAuth(fetchOnboardingInvestmentProfile);
const useFetchShowEsgWarning = useFetchAuth(fetchShowEsgWarning);

const getProfileIndex = (profiles, profileId, defaultIndex = null) => {
	if (!profiles) {
		return defaultIndex;
	}

	for (let i = 0; i < profiles.length; i += 1) {
		if (profiles[i].id === profileId) {
			return i;
		}
	}

	return defaultIndex;
};

const initialEsg = [null, false];

const updateEsgToggle = (nextEsgToggle) => (esg) => {
	const [esgToggle] = esg;
	return nextEsgToggle !== esgToggle ? [nextEsgToggle, false] : esg;
};

const updateEsgWarningDismissed = (esg) => {
	const [esgToggle, esgWarningDismissed] = esg;
	return !esgWarningDismissed ? [esgToggle, true] : esg;
};

export default function InvestorProfileChoice({
	category,
	childId,
	closeAccount,
	disabled,
	esgDefault,
	faqUrl,
	investmentQuestionnaireResetAvailable,
	reloadQuestionnaire,
}) {
	const [t] = useTranslation();
	const country = useCountry();
	const language = useLanguage();

	const [[esgToggle, esgWarningDismissed], setEsg] = useState(initialEsg);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [notRecommendedModalOpen, setNotRecommendedModalOpen] = useState(false);

	const [profiles, loading] = useFetchInvestorProfile({}, country, language);
	const [investmentProfile, loadingInvestmentProfile] = useFetchOnboardingInvestmentProfile({}, childId);
	const [showEsgWarning] = useFetchShowEsgWarning(false, childId);
	const resetInvestmentQuestionnaireAuth = useRequestAuth(onboardingResetInvestmentQuestionnaire);
	const saveOnboardingInvestorProfileAuth = useRequestAuth(saveOnboardingInvestorProfile);
	const disabledOrWarning = disabled || !!investmentProfile.warning;

	const setEsgToggle = useCallback((nextEsgToggle) => {
		const nextRecommendedProfiles = nextEsgToggle
			? investmentProfile.recommendedProfilesEsg
			: investmentProfile.recommendedProfiles;
		const nextDefaultProfile = nextEsgToggle
			? investmentProfile.defaultProfileEsg
			: investmentProfile.defaultProfile;

		if (
			loadingInvestmentProfile
			|| !nextRecommendedProfiles
			|| nextRecommendedProfiles.length === 0
			|| !nextDefaultProfile
		) {
			return;
		}

		setSelectedProfile(null);
		setEsg(updateEsgToggle(nextEsgToggle));
	}, [investmentProfile, loadingInvestmentProfile]);
	const setEsgWarningDismissed = useCallback(() => setEsg(updateEsgWarningDismissed), []);

	const esgAvailable = !loadingInvestmentProfile
		&& !!investmentProfile.recommendedProfilesEsg
		&& investmentProfile.recommendedProfilesEsg.length > 0
		&& !!investmentProfile.defaultProfileEsg;
	const esgActive = esgAvailable && (esgToggle ?? esgDefault);

	const investorProfiles = esgActive ? profiles.investorProfilesEsg : profiles.investorProfiles;
	const defaultProfile = esgActive ? investmentProfile.defaultProfileEsg : investmentProfile.defaultProfile;
	const recommendedProfiles = esgActive
		? investmentProfile.recommendedProfilesEsg
		: investmentProfile.recommendedProfiles;
	const currentProfile = selectedProfile ?? defaultProfile ?? null;
	const currentSlide = getProfileIndex(investorProfiles, currentProfile, 3);

	const setCurrentSlide = useCallback((profileId) => {
		const { id = null } = investorProfiles?.[profileId] ?? {};
		if (id === null || id === selectedProfile) {
			return;
		}

		setSelectedProfile(id);
		if (recommendedProfiles === null || recommendedProfiles?.includes(id) === false) {
			setNotRecommendedModalOpen(true);
		}
	}, [selectedProfile, investorProfiles, recommendedProfiles]);

	if (loading || loadingInvestmentProfile) {
		return null;
	}

	const handleResetInvestmentQuestionnaire = async () => {
		await resetInvestmentQuestionnaireAuth(childId);
		reloadQuestionnaire();
	};

	return (
		<Formik
			initialValues={{}}
			onSubmit={async () => {
				try {
					await saveOnboardingInvestorProfileAuth(childId, currentProfile, category);
				} catch {
					return;
				}
				reloadQuestionnaire();
			}}
		>
			{({
				handleSubmit,
			}) => (
				<form onSubmit={handleSubmit}>
					<h3 className={styles.title}>{t('investorProfileChoice.title')}</h3>
					<p className={styles.text}>
						{t('investorProfileChoice.text')}
					</p>
					{defaultProfile && (
						<>
							{esgAvailable && (
								<div className={styles.toggleWrap}>
									<Toggle
										labelFirst={t('investorProfilePreview.classicPortfolios')}
										labelSecond={t('investorProfilePreview.esgPortfolios')}
										setToggle={setEsgToggle}
										toggle={esgActive}
										tooltipFirst={t('investorProfileChoice.tooltip.classic')}
										tooltipSecond={t('investorProfileChoice.tooltip.esg')}
									/>
								</div>
							)}
							<InvestorProfileDetailSlider
								currentSlide={currentSlide}
								products={profiles.products ?? []}
								profiles={investorProfiles ?? []}
								setCurrentSlide={setCurrentSlide}
								showProducts={false}
							/>
							<InvestorProfileDetailSelection
								currentSlide={currentSlide}
								onSelectionChange={setCurrentSlide}
								profiles={investorProfiles ?? []}
								profilesRecommend={recommendedProfiles ?? null}
							/>
							<div className={styles.button}>
								<Button
									disabled={disabledOrWarning}
									isSubmit
									label={t('investorProfileChoice.submit')}
								/>
							</div>
							<div className={styles.modalLinks}>
								<Button
									disabled={disabledOrWarning || !investmentQuestionnaireResetAvailable}
									isLink
									label={t('investorProfileChoice.redo')}
									onClick={handleResetInvestmentQuestionnaire}
									tooltip={!investmentQuestionnaireResetAvailable ? t('investorProfileChoice.notRecommended.tooltip') : null}
								/>
							</div>
							<Modal
								isVisible={!disabledOrWarning && showEsgWarning && esgActive && !esgWarningDismissed}
								onClose={setEsgWarningDismissed}
								title={t('general.esgWarningModal.title')}
							>
								<div className={styles.modal}>
									<p>{t('general.esgWarningModal.text')}</p>
									<div className={styles.modalFooter}>
										<Button
											disabled={disabledOrWarning}
											label={t('general.esgWarningModal.button')}
											onClick={setEsgWarningDismissed}
										/>
									</div>
								</div>
							</Modal>
							<Modal
								isVisible={!disabledOrWarning && notRecommendedModalOpen}
								onClose={() => {
									setSelectedProfile(null);
									setNotRecommendedModalOpen(false);
								}}
								title={t('investorProfileChoice.notRecommended.title')}
							>
								<div className={styles.modal}>
									<Trans i18nKey="investorProfileChoice.notRecommended.message">
										<p className={styles.modalText} />
									</Trans>
									<Button
										disabled={disabledOrWarning}
										label={t('investorProfileChoice.notRecommended.close')}
										onClick={() => {
											setSelectedProfile(null);
											setNotRecommendedModalOpen(false);
										}}
									/>
									<div className={styles.modalButtons}>
										<Button
											disabled={disabledOrWarning || !investmentQuestionnaireResetAvailable}
											label={t('investorProfileChoice.notRecommended.redo')}
											onClick={handleResetInvestmentQuestionnaire}
											outline
											tooltip={!investmentQuestionnaireResetAvailable ? t('investorProfileChoice.notRecommended.tooltip') : null}
										/>
									</div>
									<div className={styles.modalLinks}>
										<Button
											disabled={disabledOrWarning}
											isLink
											label={t('investorProfileChoice.notRecommended.submitAbort')}
											onClick={closeAccount}
										/>
									</div>
								</div>
							</Modal>
						</>
					)}
					<Modal
						isVisible={!disabled && investmentProfile.warning === 'littleExperience'}
						title={t('investorProfileChoice.littleExperience.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								<Trans i18nKey="investorProfileChoice.littleExperience.message">
									<Link to={faqUrl} />
								</Trans>
							</p>
							<Button
								disabled={disabled || !investmentQuestionnaireResetAvailable}
								label={t('investorProfileChoice.littleExperience.reset')}
								onClick={handleResetInvestmentQuestionnaire}
								tooltip={!investmentQuestionnaireResetAvailable ? t('investorProfileChoice.notRecommended.tooltip') : null}
							/>
							<div className={styles.modalLinks}>
								<Button
									disabled={disabled}
									isLink
									label={t('investorProfileChoice.littleExperience.abort')}
									onClick={closeAccount}
								/>
							</div>
						</div>
					</Modal>
					<Modal
						isVisible={!disabled && investmentProfile.warning === 'notSuitable'}
						title={t('investorProfileChoice.notSuitable.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{t('investorProfileChoice.notSuitable.message')}
							</p>
							<p className={styles.modalText}>
								{t('investorProfileChoice.notSuitable.text')}
							</p>
							<Button
								disabled={disabled || !investmentQuestionnaireResetAvailable}
								label={t('investorProfileChoice.notSuitable.back')}
								onClick={handleResetInvestmentQuestionnaire}
								tooltip={!investmentQuestionnaireResetAvailable ? t('investorProfileChoice.notRecommended.tooltip') : null}
							/>
							<div className={styles.modalLinks}>
								<Button
									disabled={disabled}
									isLink
									label={t('investorProfileChoice.notSuitable.abort')}
									onClick={closeAccount}
								/>
							</div>
						</div>
					</Modal>
					<Modal
						isVisible={!disabled && investmentProfile.warning === 'notConsistent'}
						title={t('investorProfileChoice.notConsistent.title')}
					>
						<div className={styles.modal}>
							<p className={styles.modalText}>
								{t('investorProfileChoice.notConsistent.text')}
							</p>
							<Button
								disabled={disabled || !investmentQuestionnaireResetAvailable}
								label={t('investorProfileChoice.notSuitable.back')}
								onClick={handleResetInvestmentQuestionnaire}
								tooltip={!investmentQuestionnaireResetAvailable ? t('investorProfileChoice.notRecommended.tooltip') : null}
							/>
							<div className={styles.modalLinks}>
								<Button
									disabled={disabled}
									isLink
									label={t('investorProfileChoice.notSuitable.abort')}
									onClick={closeAccount}
								/>
							</div>
						</div>
					</Modal>
				</form>
			)}
		</Formik>
	);
}

InvestorProfileChoice.propTypes = {
	category: PropTypes.string,
	childId: PropTypes.number,
	closeAccount: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	esgDefault: PropTypes.bool,
	faqUrl: PropTypes.string.isRequired,
	investmentQuestionnaireResetAvailable: PropTypes.bool.isRequired,
	reloadQuestionnaire: PropTypes.func.isRequired,
};

InvestorProfileChoice.defaultProps = {
	category: null,
	childId: null,
	disabled: false,
	esgDefault: false,
};
